import React from "react";
import { Box, Heading, Text, VStack, useColorModeValue } from "@chakra-ui/react";

const CardInfoUpdated = () => {
  const sectionBg = useColorModeValue("white", "gray.800");

  return (
    <Box h="100vh" w="100%" bg={sectionBg} display="flex" justifyContent="center" alignItems="center">
      <VStack spacing={4} align="center">
        <Heading size="lg" color="green.500">
          Payment Method Updated!
        </Heading>
        <Text>Your payment information has been successfully updated.</Text>
      </VStack>
    </Box>
  );
};

export default CardInfoUpdated;
