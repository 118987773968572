import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// Clear any previously stored color mode settings
localStorage.removeItem("chakra-ui-color-mode");

const theme = extendTheme({
  fonts: {
    heading: `'Proxima Nova', sans-serif`,
    body: `'Proxima Nova', sans-serif`,
  },
  config: {
    initialColorMode: "light", // Force light mode as the default.
    useSystemColorMode: false, // Disable system color mode to always use light mode.
  },
  components: {
    Popover: {
      baseStyle: {
        content: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>,
  document.getElementById("root")
);
