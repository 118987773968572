import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Button,
  Checkbox,
  Box,
  Image,
  Link as ChakraLink,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import SF from "../assets/images/SF-Image.png";

const FirstLoginPopup = ({ isOpen, onClose }) => {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  // When user checks the box
  const handleCheckboxChange = (e) => {
    setDoNotShowAgain(e.target.checked);
  };

  // When user clicks "Got It!"
  const handleCloseWithCheckbox = () => {
    // Pass doNotShowAgain back to parent
    onClose(doNotShowAgain);
  };

  // Some styling
  const bg = useColorModeValue("white", "gray.800");
  const headingColor = useColorModeValue("blue.800", "blue.300");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("blue.700", "blue.700");

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(false)} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="xl"
        borderRadius="xl"
        p={6}
        bg={bg}
        // Keep the rest of the content centered
        textAlign="center"
        border="2px solid"
        borderColor={borderColor}
        boxShadow="xl"
      >
        {/* Icon or image at the top */}
        <Box mt={2} mb={10}>
          <Image
            src={SF}
            alt="Profile Setup Icon"
            w="130px"
            h="auto"
            mx="auto"
          />
        </Box>

        {/* Main Heading */}
        <ModalHeader p={0} mb={2}>
          <Heading fontSize="3xl" fontWeight="bold" color={headingColor}>
            Get Started Instantly – Then Make It Even Better!
          </Heading>
        </ModalHeader>

        {/* Body Text */}
        <ModalBody p={0} mb={4}>
          <Text fontSize="md" color={textColor} lineHeight="1.7" mb={4}>
            We’ve used AI to instantly create a company-specific Product Profile
            based on your business name and URL to get you started. For the best
            results, head over to{" "}
            <ChakraLink
              as={RouterLink}
              to="/outreach_profiles"
              color="blue.500"
              fontWeight="bold"
              textDecoration="underline"
            >
              Product Profiles
            </ChakraLink>{" "}
            to refine what’s been generated or add new profiles for different
            products or offerings. The more compelling your product profile is,
            the more powerful and precise your AI-generated content will be!
          </Text>
        </ModalBody>

        {/* "Don't show me again" checkbox on the left */}
        <Box mb={4} w="full" textAlign="left">
          <Checkbox size="md" onChange={handleCheckboxChange}>
            Don’t show me again
          </Checkbox>
        </Box>

        {/* Footer / Action Button */}
        <ModalFooter p={0}>
          <Button
            bg="#0F123F"
            _hover="#0F123F"
            color="white"
            width="100%"
            onClick={handleCloseWithCheckbox}
          >
            Got It!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FirstLoginPopup;
