import React from "react";
import {Link as RouterLink } from "react-router-dom";
import {
  Box,
  VStack,
  HStack,
  Link,
  IconButton,
  Text,
  Image,
  Divider,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaLinkedin } from "react-icons/fa";
// import SlackIcon from "../../assets/svg/slack.svg"; // Path to Slack icon SVG
import Logo from "../../assets/images/logo-home-dark.png"; // Path to Logo SVG

const Footer = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      as="footer"
      py={8}
      px={{ base: 4, md: 16 }}
      bg="white"
      color="gray.800"
    >
      <VStack spacing={6} align="stretch">
        {/* Top Section: Logo and Navigation */}
        <VStack spacing={4} align={isMobile ? "stretch" : "stretch"}>
          {/* Logo */}
          <HStack
            justifyContent={isMobile ? "space-between" : "space-between"}
            w="100%"
          >
            <HStack spacing={4} justifyContent="center">
            <Link as={RouterLink} to="/home">
              <Image
                src={Logo}
                alt="Signal Logo"
                height={{ base: "50px", md: "70px", lg: "80px" }}
                width="auto"
              />
              </Link>
            </HStack>

            {/* Navigation Links */}
            {!isMobile && (
              <HStack spacing={8} fontSize="sm" fontWeight="medium" mr={24}>
                <Link href="/home" color="gray.800" fontWeight="bold">
                  Home
                </Link>
                <Link href="/pricing" color="gray.800" fontWeight="bold">
                  Pricing
                </Link>
                <Link href="/about-us" color="gray.800" fontWeight="bold">
                  About Us
                </Link>
              </HStack>
            )}

            {/* Social Icons */}
            <HStack
              spacing={2}
              justifyContent={isMobile ? "center" : "flex-end"}
            >
              {/* <IconButton
                as="a"
                href="https://slack.com"
                aria-label="Slack"
                icon={<Image src={SlackIcon} alt="Slack" boxSize="20px" />}
                variant="ghost"
                _hover={{ bg: "transparent" }}
              /> */}
              <IconButton
                as="a"
                href="#"
                aria-label="Close"
                icon={
                  <Text fontSize="lg" mb={0}>
                    X
                  </Text>
                }
                variant="ghost"
                _hover={{ bg: "transparent" }}
              />
              <IconButton
                as="a"
                href="https://www.linkedin.com/company/smart-focus-ai/about/"
                aria-label="LinkedIn"
                icon={<FaLinkedin />}
                variant="ghost"
                color="blue"
                _hover={{ bg: "transparent", color: "blue" }}
                target="_blank"
                rel="noopener noreferrer"
              />

              <Text fontSize="xs" color="gray.600" textAlign="center">
                <Link
                  href="https://www.linkedin.com/company/smart-focus-ai/about/"
                  color="gray.600"
                  _hover={{ textDecoration: "underline" }}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </Text>
            </HStack>
          </HStack>

          {isMobile && (
            <HStack
              spacing={4}
              fontSize="sm"
              fontWeight="medium"
              justifyContent="center"
            >
              <Link href="/home" color="gray.800" fontWeight="bold">
                Home
              </Link>
              <Link href="/pricing" color="gray.800" fontWeight="bold">
                Pricing
              </Link>
              <Link href="/about-us" color="gray.800" fontWeight="bold">
                About Us
              </Link>
            </HStack>
          )}
        </VStack>

        <Divider />

        {/* Bottom Section: Legal Links */}
        <VStack spacing={2} fontSize="sm" color="gray.600" textAlign="center">
          <Text>© 2025 Smart Focus AI Ltd. All rights reserved.</Text>
          <HStack spacing={4} justifyContent="center">
            <Link
              href="/terms-of-service"
              _hover={{ textDecoration: "underline" }}
            >
              Terms Of Service
            </Link>
            <Text mb={0}>|</Text>
            {/* <Divider orientation="vertical" h="auto"/> */}
            <Link
              href="/privacy-policy"
              _hover={{ textDecoration: "underline" }}
            >
              Privacy Policy
            </Link>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Footer;
