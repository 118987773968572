import React from "react";
import {
  Flex,
  Box,
  Stack,
  Link,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { FiHome, FiLock, FiUser, FiSettings, FiVideo } from "react-icons/fi";
import { useUser } from "../userRole/UserContext";
import AdminSettings from "./AdminSettings";
import Billing from "./Billing";

// Define sidebar sections: "ACCOUNT", "WORKSPACE", and "TRAINING"
const navSections = [
  {
    heading: "ACCOUNT",
    links: [
      {
        label: "Programs",
        href: "/program",
        icon: FiHome,
      },
    ],
  },
  {
    heading: "WORKSPACE",
    links: [
      {
        label: "Admin Settings",
        href: "/settings/admin",
        icon: FiSettings,
      },
      {
        label: "Billing",
        href: "/settings/billing",
        icon: FiLock,
      },
    ],
  },
  // {
  //   heading: "TRAINING",
  //   links: [
  //     {
  //       label: "Training Video",
  //       href: "/settings/training-video",
  //       icon: FiVideo,
  //     },
  //   ],
  // },
];

// Sidebar component
const Sidebar = () => {
  const location = useLocation();
  const activeBg = useColorModeValue("gray.100", "gray.700");
  const { user } = useUser();
  const role = user ? user.role : null;

  return (
    <Box
      as="nav"
      w={{ base: "100%", md: "15%" }}
      p={4}
      bg="white"
      borderRight="1px solid #E2E8F0"
      overflowY="auto"
    >
      <Stack spacing={8}>
        {navSections.map((section) => (
          <Box key={section.heading}>
            <Text
              fontSize="xs"
              color="gray.500"
              fontWeight="bold"
              textTransform="uppercase"
              mb={2}
              letterSpacing="wide"
            >
              {section.heading}
            </Text>
            <Stack spacing={1}>
              {section.links.map((link) => {
                // Render Admin Settings only if the user is an admin
                if (link.label === "Admin Settings" && role !== "Admin") {
                  return null;
                }

                const isActive = location.pathname === link.href;

                return (
                  <Link
                    key={link.label}
                    href={link.href}
                    style={{ textDecoration: "none" }}
                    _hover={{ textDecoration: "none" }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      px={3}
                      py={2}
                      borderRadius="md"
                      bg={isActive ? activeBg : "transparent"}
                      color={isActive ? "blue.600" : "gray.700"}
                      fontWeight={isActive ? "semibold" : "normal"}
                      _hover={{ bg: activeBg }}
                    >
                      <Icon as={link.icon} boxSize={4} mr={2} />
                      <Text fontSize="sm" mb={0}>
                        {link.label}
                      </Text>
                    </Box>
                  </Link>
                );
              })}
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

// Main Settings page with Router + Sidebar
const SettingsPage = () => {
  const { user } = useUser();
  const role = user ? user.role : null;
  const sectionBg = useColorModeValue("white", "gray.800");

  return (
    <Router>
      <Flex bg="#F3F4F6" h="100vh">
        {/* Left Sidebar */}
        <Sidebar />

        {/* Right Content (Switch for React Router v5) */}
        <Box flex="1">
          <Switch>
            {/* Redirect from /settings to a default page based on role */}
            <Route exact path="/settings">
              {role === "Admin" ? (
                <Redirect to="/settings/admin" />
              ) : (
                <Redirect to="/settings/billing" />
              )}
            </Route>

            {/* Admin Settings */}
            <Route path="/settings/admin">
              {role === "Admin" ? (
                <AdminSettings sectionBg={sectionBg} />
              ) : (
                <Redirect to="/settings/billing" />
              )}
            </Route>

            {/* Billing Page */}
            <Route path="/settings/billing">
              <Billing sectionBg={sectionBg} />
            </Route>

            {/* Fallback Route */}
            <Route path="*">
              <Redirect to="/settings" />
            </Route>
          </Switch>
        </Box>
      </Flex>
    </Router>
  );
};

export default SettingsPage;
