import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
  useToast,
  Checkbox,
  HStack,
} from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";
import axiosInstance from "../../helpers/axiosInstance";

const AddProfileModal = ({ isOpen, onClose, profileData, onSave }) => {
  const [profileName, setProfileName] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [keyUsp, setKeyUsp] = useState("");
  const [caseStudy, setCaseStudy] = useState("");
  const [summarizeCaseStudy, setSummarizeCaseStudy] = useState(false);
  // const [keywords, setKeywords] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  // Reset state when profileData or isOpen changes (i.e., when the modal opens)
  useEffect(() => {
    if (isOpen) {
      if (profileData) {
        // Edit mode: populate the state with profileData
        setProfileName(profileData.outreach_profile_name || "");
        setAboutUs(profileData.sender_company_product_information || "");
        setKeyUsp(profileData.key_usp || "");
        setCaseStudy(profileData.case_study || "");
        setSummarizeCaseStudy(profileData.summarize_case_study || false);
        // setKeywords(
        //   profileData.in_the_news_search_term
        //     ? profileData.in_the_news_search_term.map((term) => ({
        //         value: term,
        //         label: term,
        //       }))
        //     : []
        // );
      } else {
        // Add mode: clear the state
        setProfileName("");
        setAboutUs("");
        setKeyUsp("");
        setCaseStudy("");
        setSummarizeCaseStudy(false);
        // setKeywords([]);
      }
    }
  }, [profileData, isOpen]);

  const handleSaveProfile = async () => {
    setIsLoading(true);
    try {
      const payload = {
        outreach_profile_name: profileName,
        sender_company_product_information: aboutUs,
        key_usp: keyUsp,
        case_study: caseStudy,
        summarize_case_study: summarizeCaseStudy,
        // in_the_news_search_term: keywords.map((tag) => tag.value),
      };
      console.log("payload", payload);
      let response;
      if (profileData && profileData._id) {
        // Update profile
        response = await axiosInstance.put(
          `/profile/${profileData._id}`,
          payload
        );
      } else {
        // Add new profile
        response = await axiosInstance.post("/profile/", payload);
      }

      if (response.status === 200) {
        toast({
          title: profileData ? "Profile Updated" : "Profile Created",
          description: response.data.message, // Use message from backend
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });

        onSave();
        onClose();
      }
    } catch (error) {
      console.error("Error saving profile:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving the profile.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxH="78vh">
        <ModalHeader>
          {profileData ? "Edit Profile" : "Add New Profile"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <FormControl>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
              Profile Name
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Enter a descriptive profile name
            </Text>
            <Input
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
              placeholder="Enter profile name"
              fontSize="sm"
              borderRadius="xl"
            />
          </FormControl>

          <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
              Product or Proposition Information
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Tell us about your product or proposition.
            </Text>
            <Textarea
              value={aboutUs}
              onChange={(e) => setAboutUs(e.target.value)}
              placeholder="Write here..."
              fontSize="sm"
              borderRadius="xl"
              minH="20vh" // Minimum height
              // maxH="40vh" // Maximum height
              // overflowY="auto" 
            />
          </FormControl>

          <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
              One Key Selling Point (USP)
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Please mention one key USP or an impressive fact about your
              offering that you want to ensure is included in generated content.
            </Text>
            <Textarea
              value={keyUsp}
              onChange={(e) => setKeyUsp(e.target.value)}
              placeholder="Enter your unique selling points here..."
              fontSize="sm"
              borderRadius="xl"
              minH="20vh" // Minimum height
            />
          </FormControl>
          <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
              Case Study
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Add a relevant case study for this product or proposition.
            </Text>
            <Textarea
              value={caseStudy}
              onChange={(e) => setCaseStudy(e.target.value)}
              placeholder="Enter case study here..."
              fontSize="sm"
              borderRadius="xl"
              minH="20vh" // Minimum height
              // maxH="40vh" // Maximum height
              // overflowY="auto" 
            />
          </FormControl>
          <FormControl mt={4}>
            <HStack>
              <Heading fontSize="md" fontWeight="bold" mb={0}>
                Summarize Case Study
              </Heading>
              <Checkbox
                isChecked={summarizeCaseStudy}
                onChange={(e) => setSummarizeCaseStudy(e.target.checked)}
              />
            </HStack>
          </FormControl>
          {/* <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={0}>
              Keywords
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Add keywords.
            </Text>
            <CreatableSelect
              isMulti
              onChange={setKeywords}
              value={keywords}
              placeholder="Enter keywords..."
              borderRadius="xl"
            />
          </FormControl>
          */}
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#231F1F"
            color="#FFFFFF"
            _hover={{ bg: "#404040" }}
            borderRadius="full"
            size="sm"
            onClick={handleSaveProfile}
            isLoading={isLoading}
          >
            {profileData ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProfileModal;
