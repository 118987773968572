import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  Heading,
  Text,
  Input,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";

const AddSenderModal = ({ isOpen, onClose, profileData, onSave }) => {
  const [profileName, setProfileName] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [companyName, setCompanyName] = useState(""); // New state for company name
  const [meetingLink, setMeetingLink] = useState("");
  const [dosAndDonts, setDosAndDonts] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New state for loading
  const toast = useToast();

  // Reset state when profileData or isOpen changes (i.e., when the modal opens)
  useEffect(() => {
    if (isOpen) {
      if (profileData) {
        // Edit mode: populate the state with profileData
        setProfileName(profileData.sender_name || "");
        setAboutUs(profileData.sender_title || "");
        setCompanyName(profileData.sender_company_name || ""); // Populate company name
        setMeetingLink(profileData.meeting_link || "");
        setDosAndDonts(profileData.dos_donts || "");
      } else {
        // Add mode: clear the state
        setProfileName("");
        setAboutUs("");
        setCompanyName(""); // Clear company name
        setMeetingLink("");
        setDosAndDonts("");
      }
    }
  }, [profileData, isOpen]);

  const handleSaveProfile = async () => {
    setIsLoading(true); // Set loading to true when save starts
    try {
      const payload = {
        sender_name: profileName,
        sender_title: aboutUs,
        sender_company_name: companyName, // Include company name in payload
        meeting_link: meetingLink, // Include meeting link in payload
        dos_donts: dosAndDonts, // Include Dos and Donts in payload
      };

      let response;
      if (profileData && profileData._id) {
        // Update profile
        response = await axiosInstance.put(
          `/sender_profile/${profileData._id}`,
          payload
        );
      } else {
        // Add new profile
        response = await axiosInstance.post("/sender_profile/", payload);
      }

      if (response.data.success) {
        toast({
          title: profileData ? "Profile Updated" : "Profile Created",
          description: response.data.message, // Use message from backend
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });

        onSave();
        onClose(); // Close modal after save
      }
    } catch (error) {
      console.error("Error saving profile:", error);
      toast({
        title: "Error",
        description: "An error occurred while saving the profile.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setIsLoading(false); // Set loading to false when save completes
    }
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent h="77vh">
        <ModalHeader>
          {profileData ? "Edit Profile" : "Add New Profile"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <FormControl>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={2}>
              Sender's Full Name
            </Heading>
            {/* <Text fontSize="xs" color="gray.500" mb={2}>
              Sender's name here.
            </Text> */}
            <Input
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
              placeholder="Enter sender name"
              fontSize="sm"
              borderRadius="xl"
            />
          </FormControl>

          <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={2}>
              Job Title
            </Heading>
            {/* <Text fontSize="xs" color="gray.500" mb={2}>
              Sender's title here.
            </Text> */}
            <Textarea
              value={aboutUs}
              onChange={(e) => setAboutUs(e.target.value)}
              placeholder="Enter job title"
              fontSize="sm"
              borderRadius="xl"
              h="10vh"
            />
          </FormControl>

          <FormControl mt={4}>
            {" "}
            {/* New FormControl for company name */}
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={2}>
              Company Name
            </Heading>
            {/* <Text fontSize="xs" color="gray.500" mb={2}>
              Sender's company name here.
            </Text> */}
            <Input
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="Enter company name"
              fontSize="sm"
              borderRadius="xl"
            />
          </FormControl>
          <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={2}>
              Meeting Link (e.g. Calendly)
            </Heading>
            {/* <Text fontSize="xs" color="gray.500" mb={2}>
              Add meeting link here.
            </Text> */}

            <Input
              value={meetingLink}
              onChange={(e) => setMeetingLink(e.target.value)}
              placeholder="Enter meeting link"
              fontSize="sm"
              borderRadius="xl"
            />
          </FormControl>
          <FormControl mt={4}>
            <Heading fontSize="md" fontWeight="bold" mt={4} mb={2}>
              Do's & Don'ts
            </Heading>
            <Text fontSize="xs" color="gray.500" mb={2}>
              Outline key behaviors to follow and avoid when interacting with
              prospects.
            </Text>

            <Textarea
              value={dosAndDonts}
              onChange={(e) => setDosAndDonts(e.target.value)}
              placeholder="Write here..."
              fontSize="sm"
              borderRadius="xl"
              minH="20vh" // Minimum height
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="#231F1F"
            color="#FFFFFF"
            _hover={{ bg: "#404040" }}
            borderRadius="full"
            size="sm"
            onClick={handleSaveProfile}
            isLoading={isLoading}
          >
            {profileData ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSenderModal;
