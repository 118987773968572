import React from "react";
import { Box, keyframes } from "@chakra-ui/react";

// Define the keyframes for the pulsing dots
const pulse = keyframes`
  0%, 80%, 100% { transform: scale(0.6); } 
  40% { transform: scale(1); }
`;

const DotsSpinner = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {/* Dot 1 */}
      <Box
        as="span"
        w="8px"
        h="8px"
        bg="black"
        borderRadius="50%"
        mr="4px"
        animation={`${pulse} 1s infinite`}
      />
      {/* Dot 2 */}
      <Box
        as="span"
        w="8px"
        h="8px"
        bg="black"
        borderRadius="50%"
        mr="4px"
        animation={`${pulse} 1s infinite`}
        animationDelay="0.2s"
      />
      {/* Dot 3 */}
      <Box
        as="span"
        w="8px"
        h="8px"
        bg="black"
        borderRadius="50%"
        animation={`${pulse} 1s infinite`}
        animationDelay="0.4s"
      />
    </Box>
  );
};

export default DotsSpinner;
