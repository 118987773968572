// contentApi.js

import axiosInstance from "../../../helpers/axiosInstance";

/**
 * Fetch the current status by execution ID.
 */
export async function fetchStatusByExecutionId(programId, fileUniqueId) {
  const response = await axiosInstance.get(
    `/content-generation/get-status-by-execution-id/${programId}/${fileUniqueId}`
  );
  return response.data;
}

/**
 * Stop the execution by execution ID.
 */
export async function stopExecution(programId, fileUniqueId) {
  const response = await axiosInstance.post(
    `/content-generation/stop-execution-by-execution-id/${programId}/${fileUniqueId}`
  );
  return response.data;
}

/**
 * Retry content by execution ID.
 */
export async function retryContent(programId, fileUniqueId, executionId) {
  const response = await axiosInstance.post(
    `/content-generation/re-try-content-by-execution-id/${programId}/${fileUniqueId}/${executionId}`
  );
  return response.data;
}

/**
 * Re-execute content by execution ID.
 */
export async function reExecuteContent(programId, fileUniqueId, executionId) {
  const response = await axiosInstance.post(
    `/content-generation/re-execute-content-by-execution-id/${programId}/${fileUniqueId}/${executionId}`
  );
  return response.data;
}
