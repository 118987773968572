import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Button,
  PinInput,
  PinInputField,
  HStack,
  Link,
  useToast,
  Image,
  Divider,
  Container,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { login } from "../auth";
import { useUser } from "./userRole/UserContext";
// import logo from "../assets/images/logo_auth.png"; // Update path if needed
import logo from "../assets/images/logo-home-dark.png";
import "./../styles/styles.css"; // Adjust or remove if needed

const TwoFactorAuthPage = () => {
  const { user, setUser } = useUser();
  const [twoFACode, setTwoFACode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [timer, setTimer] = useState(120); // 2-minute countdown in seconds
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  // Retrieve the email passed from the previous login step
  const email = location.state?.email || "";

  // Redirect if no email
  useEffect(() => {
    if (!email) {
      history.push("/login");
    }

    // Start countdown
    const interval = setInterval(() => {
      setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [email, history]);

  // If user is set and login is successful, navigate to /program
  useEffect(() => {
    if (user && isLoginSuccessful) {
      history.push("/program");
      setIsLoginSuccessful(false);
    }
  }, [user, isLoginSuccessful, history]);

  // Format the timer for mm:ss display
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Handle 2FA verification
  const verify2FA = async () => {
    setIsVerifying(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, code: twoFACode }),
    };

    try {
      const response = await fetch("/auth/verify-2fa", requestOptions);
      const data = await response.json();

      if (response.status === 200) {
        // Save tokens/user data, then flag login success
        login(data.data);
        localStorage.setItem("email", data.data.email);
        localStorage.setItem("username", data.data.username);
        localStorage.setItem("company_name", data.data.company_name);
        localStorage.setItem("organization", data.data.organization);

        // Prospect credits (if you want to store sub-fields)
        // Prospect credits (if you want to store sub-fields)
        const credits = data.data.prospect_credits || {};
        //  localStorage.setItem(
        //    "monthly_credits",
        //    credits.monthly_credits || 0
        //  );
        //  localStorage.setItem(
        //    "remaining_credits",
        //    credits.remaining_credits || 0
        //  );
        //  localStorage.setItem(
        //    "expiration_date",
        //    credits.expiration_date || ""
        //  );
        //  localStorage.setItem(
        //    "plan_type",
        //    credits.plan_type || "free"
        //  );
        //  localStorage.setItem(
        //    "subscription_duration",
        //    credits.subscription_duration || "monthly"
        //  );
        setUser({
          role: data.data.role,
          organization: data.data.organization,
          monthly_credits: data.data.monthly_credits,
          remaining_credits: data.data.remaining_credits,
          expiration_date: data.data.expiration_date,
          plan_type: data.data.plan_type,
          subscription_duration: data.data.subscription_duration,
        });

        setIsLoginSuccessful(true);

        toast({
          title: "Login successful",
          description: "You are now logged in.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        history.push("/login");
        toast({
          title: "2FA verification failed",
          description:
            data.message || "The 2FA code is invalid or has expired.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      history.push("/login");
      toast({
        title: "Error",
        description: "Failed to connect to the server.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsVerifying(false);
  };

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      bg="#F7F7F7" // or any background color you prefer
    >
      <Box
        w="full"
        maxW="md"
        bg="white"
        p={{ base: 6, md: 8 }}
        boxShadow="lg"
        borderRadius="lg"
      >
        {/* Logo (optional) */}
        <Flex justify="center" mb={4}>
          <Image src={logo} alt="Logo" width="200px" h="auto" />
        </Flex>

        <Stack spacing={4} textAlign="center">
          {/* Heading */}
          <Heading
            size="md"
            fontFamily="'Manrope', sans-serif"
            color="#3A4135"
            fontWeight="medium"
          >
            Authenticate Your Account
          </Heading>

          {/* Subheading or instructions */}
          <Text color="gray.600" fontSize="sm">
            Protecting your account is our top priority. Please confirm your
            account by entering the authorization code sent to:
            <br />
            <Text as="span" fontWeight="bold">
              {email}
            </Text>
          </Text>

          {/* PinInput for 6-digit code */}
          <HStack justify="center">
            <PinInput
              otp
              size="lg"
              onChange={(value) => setTwoFACode(value)}
              placeholder=""
            >
              {Array(6)
                .fill("")
                .map((_, i) => (
                  <PinInputField key={i} />
                ))}
            </PinInput>
          </HStack>

          {/* Timer message */}
          <Text fontSize="sm" mt={1} color={timer > 0 ? "black" : "red"}>
            Time remaining: {formatTime(timer)}
          </Text>

          {/* If timer is zero, prompt user to go back and request a new code */}
          {timer === 0 && (
            <Text fontSize="sm" color="red" mt={1}>
              Your code has expired. Please{" "}
              <Link href="/login" color="blue.600" fontWeight="medium">
                go back to login
              </Link>{" "}
              to request a new code.
            </Text>
          )}

          {/* Submit button */}
          <Button
            colorScheme="blue"
            onClick={verify2FA}
            isLoading={isVerifying}
            isDisabled={timer === 0 || twoFACode.length < 6}
          >
            Submit
          </Button>

          {/* Resend code link (optional) */}
          {/* <Text fontSize="sm" color="gray.600">
            Didn’t receive your code?{" "}
            <Link
              color="blue.600"
              fontWeight="medium"
              onClick={() => {
                // Implement "resend code" logic as needed
                toast({
                  title: "Code resent",
                  description:
                    "A new 2FA code has been sent. Please check your email.",
                  status: "info",
                  duration: 4000,
                  isClosable: true,
                });
              }}
            >
              Resend a new code
            </Link>
          </Text> */}
        </Stack>

        {/* Optional Footer (e.g., Terms, Privacy) */}
        <Box pt={10}>
          <HStack spacing={2} align="center" justify="center">
            <Link
              fontSize="sm"
              href="/terms-of-service"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="blue.600"
            >
              Terms of Use
            </Link>
            <Divider
              orientation="vertical"
              height="12px"
              borderColor="blackAlpha.400"
              borderWidth="0.5px"
            />
            <Link
              fontSize="sm"
              href="/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="blue.600"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default TwoFactorAuthPage;
