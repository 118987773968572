import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  Checkbox,
  useToast,
  Image,
  Divider,
  VStack,
} from "@chakra-ui/react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { login } from "../auth";
import { useUser } from "./userRole/UserContext";
import { HiEye, HiEyeOff } from "react-icons/hi";
import logo from "../assets/images/logo-home-dark.png";
import "./../styles/styles.css";

const content = [
  {
    heading: "Smart Focus AI",
    text: "Revolutionizing Personalized Outreach with Cutting-Edge AI Solutions.",
  },
  {
    heading: "Smart Focus AI",
    text: "Generate Personalized Emails for Your Leads, Instantly.",
  },
  {
    heading: "Smart Focus AI",
    text: "Create Tailored LinkedIn Messages for Enhanced Engagement.",
  },
  {
    heading: "Smart Focus AI",
    text: "Design Personalized Calling Scripts for Effective Conversations.",
  },
];

const LoginPage = () => {
  const { user, setUser } = useUser();
  const toast = useToast();
  const history = useHistory();

  // React Hook Form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      rememberMe: true, // Checkbox will be checked by default
    },
  });

  // Local states
  const [showPassword, setShowPassword] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Rotate text every 12 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setAnimationTrigger((prev) => !prev);
        return (prevIndex + 1) % content.length;
      });
    }, 12000);
    return () => clearInterval(timer);
  }, []);

  // Typewriter effect trigger after 10 seconds
  useEffect(() => {
    const textElement = document.querySelector(".typewriter");
    if (textElement) {
      const timer = setTimeout(() => {
        textElement.classList.add("wrapText");
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, animationTrigger]);

  const onSubmit = async (formData) => {
    setIsSubmitting(true);

    const loginPayload = {
      email: formData.email,
      password: formData.password,
      remember_me: formData.rememberMe || false,
    };

    try {
      const response = await fetch("/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(loginPayload),
      });

      const data = await response.json();

      if (response.ok) {
        if (
          data.success === true &&
          data.data &&
          data.data.access_token &&
          data.data.refresh_token
        ) {
          login(data.data);
          localStorage.setItem("email", data.data.email);
          localStorage.setItem("username", data.data.username);
          localStorage.setItem("company_name", data.data.company_name);
          localStorage.setItem("organization", data.data.organization);

          // Prospect credits (if you want to store sub-fields)
          const credits = data.data.prospect_credits || {};
          // localStorage.setItem(
          //   "monthly_credits",
          //   credits.monthly_credits || 0
          // );
          // localStorage.setItem(
          //   "remaining_credits",
          //   credits.remaining_credits || 0
          // );
          // localStorage.setItem(
          //   "expiration_date",
          //   credits.expiration_date || ""
          // );
          // localStorage.setItem(
          //   "plan_type",
          //   credits.plan_type || "free"
          // );
          // localStorage.setItem(
          //   "subscription_duration",
          //   credits.subscription_duration || "monthly"
          // );

          setUser({
            role: data.data.role,
            organization: data.data.organization,
            monthly_credits: data.data.monthly_credits,
            remaining_credits: data.data.remaining_credits,
            expiration_date: data.data.expiration_date,
            plan_type: data.data.plan_type,
            subscription_duration: data.data.subscription_duration,
          });
          history.push("/program");

          toast({
            title: "Login Successful",
            description: "Welcome back! You are now logged in.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else if (
          data.success === true &&
          data.message?.includes("2FA code sent")
        ) {
          toast({
            title: "2FA code sent",
            description: "Please check your email to complete 2FA.",
            status: "info",
            duration: 5000,
            isClosable: true,
          });
          history.push("/two-factor-auth", { email: data.data?.email });
        } else {
          toast({
            title: "Unexpected response",
            description: data.message || "Please try again or contact support.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Login Failed",
          description: data.message || "Invalid credentials. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Unable to connect to the server. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
      reset();
    }
  };

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      h={{ base: "auto", md: "100vh" }}
      overflowY="auto"
      overflowX="hidden"
    >
      {/* Left Side - Rotating Text & Typewriter Effect */}
      <Box
        w={{ base: "100%", md: "60%" }}
        h={{ base: "auto", md: "100vh" }}
        bg="#FAC900"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={4}
      >
        <VStack spacing={4} w={{ base: "90%", md: "auto" }}>
          <Link as={RouterLink} to="/home">
            <Image
              src={logo}
              alt="Logo"
              w={{ base: "200px", md: "300px" }}
              h="auto"
              loading="lazy"
            />
          </Link>
          <Text
            color="#0F123F"
            className={`typewriter ${animationTrigger ? "alternate" : ""}`}
            key={currentIndex}
            textAlign="center"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
            fontSize={{ base: "sm", md: "lg" }}
          >
            {content[currentIndex].text}
          </Text>
        </VStack>
      </Box>

      {/* Right Side - Login Form */}
      <Box
        w={{ base: "100%", md: "40%" }}
        bg="white"
        p={{ base: 6, md: 10 }}
        alignContent="center"
        h={{ base: "auto", md: "100vh" }}
        overflowY="auto"
      >
        <Container maxW="container.sm">
          <Stack spacing={4}>
            <Heading
              as="h1"
              size={{ base: "md", md: "xl" }}
              textAlign="left"
              mb={4}
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#3A4135"
            >
              Welcome back
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Email Field */}
              <FormControl isInvalid={errors.email} mb={4} isRequired>
                <FormLabel fontSize="sm">Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter your email"
                  {...register("email", { required: "Email is required" })}
                />
              </FormControl>

              {/* Password Field */}
              <FormControl isInvalid={errors.password} mb={4} isRequired>
                <FormLabel fontSize="sm">Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    {...register("password", {
                      required: "Password is required",
                    })}
                  />
                  <InputRightElement>
                    <IconButton
                      size="sm"
                      onClick={() => setShowPassword(!showPassword)}
                      icon={showPassword ? <HiEyeOff /> : <HiEye />}
                      aria-label="Toggle password visibility"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              {/* Remember Me Checkbox */}
              <FormControl mb={4}>
                <Checkbox defaultChecked {...register("rememberMe")}>
                  Remember me
                </Checkbox>
              </FormControl>

              {/* Forgot Password Link */}
              <Link
                href="/forgot_password"
                fontFamily="'Manrope', sans-serif"
                fontWeight="medium"
                color="#3A4135"
                fontSize="sm"
              >
                Forgot Password?
              </Link>

              {/* Submit Button */}
              <Button
                mt={4}
                colorScheme="blue"
                type="submit"
                w="100%"
                bg="#231F1F"
                color="#FFFFFF"
                fontFamily="'Roboto', sans-serif"
                fontWeight="medium"
                _hover={{ bg: "#404040" }}
                isLoading={isSubmitting}
              >
                Login
              </Button>

              {/* Sign Up Link */}
              <Text
                mt={4}
                mb={0}
                fontSize="sm"
                fontFamily="'Manrope', sans-serif"
                fontWeight="medium"
                color="#3A4135"
                textAlign="center"
              >
                Don't have an account?{" "}
                <Link
                  href="/signup"
                  fontFamily="'Manrope', sans-serif"
                  fontWeight="medium"
                  color="#0F123F"
                >
                  Sign up
                </Link>
              </Text>
            </form>
          </Stack>
        </Container>

        {/* Footer Links */}
        <Box alignContent="center" justifyContent="center" p={5} pb={0} pt={10}>
          <HStack alignContent="center" justifyContent="center">
            <Link
              fontSize="12"
              href="/terms-of-service"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
            >
              Terms of Use
            </Link>
            <Divider
              alignSelf="center"
              orientation="vertical"
              height="12px"
              borderColor="black"
              borderWidth="0.5px"
            />
            <Link
              fontSize="12"
              href="/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default LoginPage;
