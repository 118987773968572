import React from "react";
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const PaymentFailurePage = () => {
  const history = useHistory();

  return (
    <Flex minH="100vh" align="center" justify="center" bg="#F7F7F7">
      <Box
        w="full"
        maxW="md"
        bg="white"
        p={{ base: 6, md: 8 }}
        boxShadow="lg"
        borderRadius="lg"
        textAlign="center"
      >
        <Heading size="lg" color="orange">
          Payment Details Not Completed
        </Heading>
        <Text mt={4} color="gray.600">
          You have chosen to not complete your purchase at this time. Please
          close this window and return to the Smart Focus AI Platform, where if
          you would like to complete the transaction, click
          upgrade and try again.
        </Text>
        {/* <Button
          mt={6}
          colorScheme="blue"
          onClick={() => history.push("/signup")}
        >
          SignUp
        </Button> */}
      </Box>
    </Flex>
  );
};

export default PaymentFailurePage;
