// 1. Define all your currency rates in one place:
export const currencyRates = {
    UK: {
      symbol: "£",
      monthlyIndividual: 21,
      monthlyTeam: 21,
      annualIndividual: 16, // cost per month if billed annually
      annualTeam: 16,       // cost per month (per seat) if billed annually
    },
    EU: {
      symbol: "€",
      monthlyIndividual: 25,
      monthlyTeam: 25,
      annualIndividual: 20,
      annualTeam: 20,
    },
    US: {
      symbol: "$",
      monthlyIndividual: 25,
      monthlyTeam: 25,
      annualIndividual: 20,
      annualTeam: 20,
    },
  };
  
  // 2. A helper function to compute final prices given
  //    the currency, seatCount, and whether billing is annual:
  export function getCurrencyData(currency, seatCount, isAnnual) {
    const {
      symbol,
      monthlyIndividual,
      monthlyTeam,
      annualIndividual,
      annualTeam,
    } = currencyRates[currency];
  
    // Convert "annual per month" to total annual cost
    const individualAnnualPrice = annualIndividual;
    const teamsAnnualPricePerSeat = annualTeam;
  
    // The final monthly or annual price for each plan
    const individualMonthlyPrice = monthlyIndividual;
    const teamsMonthlyPricePerSeat = monthlyTeam;
  
    // Multiply by seat count for the Teams plan
    const teamsMonthlyPrice = seatCount * teamsMonthlyPricePerSeat;
    const teamsAnnualPrice = seatCount * teamsAnnualPricePerSeat * 12;
  
    const TotalindividualAnnualPrice = individualAnnualPrice * 12;

    const TotalteamsAnnualPricePerSeat = seatCount * teamsAnnualPricePerSeat * 12;
    
    // Return all relevant pricing info
    return {
      symbol,
      individualMonthlyPrice,
      individualAnnualPrice,
      teamsMonthlyPricePerSeat,
      teamsAnnualPricePerSeat,
      TotalteamsAnnualPricePerSeat,
      TotalindividualAnnualPrice,
      teamsMonthlyPrice,
    };
  }
  