import React, { useState, useEffect } from "react";
import axiosInstance from "../../helpers/axiosInstance";
import {
  Box,
  Heading,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Text,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Input,
  Button,
  useColorModeValue,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  revokeCredits,
  grantCredits,
  getCurrentPlan,
} from "../../api/subscriptionApi";
import {
  createOrganization,
  getCurrentOrganization,
  getOrganizationByEmail,
  updateOrganization,
  fetchSettings,
  updateSettings,
  deleteOrganization,
} from "../../api/organizationApi";
import Credentials from "../websiteSettings/Credentials";
import AdminSubscriptionInfo from "./AdminSubscriptionInfo";

const AdminSettings = ({ sectionBg }) => {
  const toast = useToast();

  const [subscription, setSubscription] = useState(null);
  const [grantCreditAmount, setGrantCreditAmount] = useState("");
  const [grantDays, setGrantDays] = useState(""); // NEW state
  const [revokeCreditAmount, setRevokeCreditAmount] = useState("");
  const [newOrganization, setNewOrganization] = useState("");
  const [modulesAllowedCreate, setModulesAllowedCreate] = useState("");
  const [emailForUpdate, setEmailForUpdate] = useState("");
  const [organizationByEmail, setOrganizationByEmail] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedOrganizationName, setSelectedOrganizationName] = useState("");
  const [seatCountCreate, setSeatCountCreate] = useState("");
  const [settings, setSettings] = useState({
    modulesAllowed: 0,
    seat_count: 0,
    //... other settings can be added here
  });
  const [organizationToDelete, setOrganizationToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteOrgEmail, setDeleteOrgEmail] = useState("");

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const res = await getCurrentPlan();
        if (res.success) {
          setSubscription(res.data);
        } else {
          toast({
            title: "Error",
            description: res.message || "Failed to fetch subscription details.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (err) {
        console.error(err);
        toast({
          title: "Error",
          description: "An error occurred while fetching subscription details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fetchSubscription();
  }, [toast]);

  // Grant Credits
  const handleGrant = async () => {
    try {
      const res = await grantCredits(grantCreditAmount, grantDays);
      toast({
        title: res.success ? "Credits Granted" : "Error",
        description: res.message,
        status: res.success ? "success" : "error",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.error(err);
      toast({
        title: "Error",
        description: "Failed to grant credits. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Revoke Credits
  const handleRevoke = async () => {
    try {
      const res = await revokeCredits(revokeCreditAmount);
      toast({
        title: res.success ? "Credits Revoked" : "Error",
        description: res.message,
        status: res.success ? "success" : "error",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.error(err);
      toast({
        title: "Error",
        description: "Failed to revoke credits. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Create New Organization
  const handleNewOrganization = async () => {
    try {
      const payload = {
        organization: newOrganization,
        settings: {
          modulesAllowed: modulesAllowedCreate,
          seat_count: seatCountCreate,
        },
      };
      const res = await createOrganization(payload);
      toast({
        title: "Organization Created",
        description: "The new organization was created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setNewOrganization("");
      setModulesAllowedCreate("");
      setSeatCountCreate("");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create a new organization. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCurrentOrganization = async () => {
    try {
      const res = await getCurrentOrganization();
      // console.log("fetchCurrentOrganization",res);
      if (res.data?.organization) {
        setSelectedOrganization(res.data.organization);
        setSelectedOrganizationName(res.data.company_name);
        localStorage.setItem("company_name", res.data.company_name);
      } else {
        setSelectedOrganization("");
      }
    } catch (error) {
      console.error("Error fetching current organization:", error);
    }
  };

  useEffect(() => {
    fetchCurrentOrganization();
  }, []);

  const handleUpdateOrganizationByEmail = async () => {
    try {
      const res = await getOrganizationByEmail(emailForUpdate);
      const organization = res.data.organization;
      setOrganizationByEmail(organization);
      await updateOrganization(organization);
      toast({
        title: "Organization Updated",
        description: `Successfully switched to the organization: ${organization}.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setEmailForUpdate("");
      fetchCurrentOrganization();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update the organization. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchCurrentOrganizationSettings = async () => {
    try {
      const response = await fetchSettings(selectedOrganization);
      setSettings(response.data.settings);
      // toast({
      //   title: "Settings Fetched",
      //   description: "Organization settings fetched successfully.",
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });
    } catch (err) {
      console.error(err);
      toast({
        title: "Error",
        description: "Failed to fetch organization settings.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (selectedOrganization) fetchCurrentOrganizationSettings();
  }, [selectedOrganization]);

  const updateCurrentOrganizationSettings = async () => {
    // console.log("in updateCurrentOrganizationSettings");
    try {
      const payload = {
        organization: selectedOrganization,
        settings: settings,
      };
      console.log("Update Settings payload:", payload);
      const res = await updateSettings(payload);
      toast({
        title: "Settings Updated",
        description: "Organization settings updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchCurrentOrganizationSettings();
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Failed to update organization settings. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleFetchOrganizationToDelete = async () => {
    try {
      const res = await getOrganizationByEmail(deleteOrgEmail);
      setOrganizationToDelete(res.data);
      toast({
        title: "Organization Found",
        description: `Found organization: ${res.data.company_name}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          "Failed to find organization. Please check the email and try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setOrganizationToDelete(null);
    }
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteOrganization = async () => {
    if (!organizationToDelete?.organization) return;

    try {
      await deleteOrganization(organizationToDelete.organization);
      toast({
        title: "Organization Deleted",
        description: "The organization has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setOrganizationToDelete(null);
      setIsDeleteModalOpen(false);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete the organization. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box h="100vh" w="100%" overflowY="auto" bg={sectionBg}>
      {/* Breadcrumb */}
      <Breadcrumb spacing={2} pt={4} pl={4} pr={4} fontSize="lg" mb={0} ml="-8">
        <BreadcrumbItem>
          <BreadcrumbLink fontWeight="semibold" href="/settings/general">
            Settings
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/settings/admin">Admin</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider m={0} p={0} />

      <VStack p={8} align="start" spacing={8}>
        {/* Organization Settings */}
        <VStack align="start" w="100%" spacing={6}>
          <Box w="100%" borderBottom="1px solid" borderColor="blackAlpha.200">
            <Heading as="h2" size="md">
              Organization Settings
            </Heading>
          </Box>

          {/* Current Organization */}
          <FormControl>
            <HStack
              justifyContent="space-between"
              alignItems="center"
              // spacing={8}
            >
              <VStack align="start" spacing={0}>
                <FormLabel fontWeight="semibold" mb={0}>
                  Current Organization
                </FormLabel>
                <Text fontSize="sm" color="gray.500" mb={0}>
                  This is your current organization.
                </Text>
              </VStack>

              <Text mb={0}>{selectedOrganizationName}</Text>
            </HStack>
          </FormControl>

          {/* New: Update Organization by Email */}
          <FormControl>
            <VStack align="start" spacing={0} mb={2}>
              <FormLabel fontWeight="semibold" mb={0}>
                Update Organization by Email
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={0}>
                Enter an email address to locate and switch to the associated
                organization.
              </Text>
            </VStack>
            <VStack align="start" spacing={2} w="50%">
              <Input
                placeholder="Enter email address"
                value={emailForUpdate}
                onChange={(e) => setEmailForUpdate(e.target.value)}
                fontSize="sm"
                borderRadius="xl"
              />
              <Button
                colorScheme="blue"
                borderRadius="full"
                size="sm"
                onClick={handleUpdateOrganizationByEmail}
              >
                Update Organization
              </Button>
            </VStack>
          </FormControl>

          {/* 2) Update Organization settings */}
          <FormControl>
            {/* Heading + Description */}
            <VStack align="start" spacing={0} mb={2}>
              <FormLabel fontWeight="semibold" mb={0}>
                Update Organization Settings
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={0}>
                Update your current organization's settings here.
              </Text>
            </VStack>

            {/* Inputs + Button */}
            <VStack align="start" spacing={2} w="50%">
              <Input
                placeholder="Programs Allowed"
                type="number"
                value={settings.modulesAllowed}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    modulesAllowed: parseInt(e.target.value, 10),
                  })
                }
                fontSize="sm"
                borderRadius="xl"
              />
              <Input
                placeholder="Seat Count"
                type="number"
                value={settings.seat_count}
                onChange={(e) =>
                  setSettings({
                    ...settings,
                    seat_count: parseInt(e.target.value, 10),
                  })
                }
                fontSize="sm"
                borderRadius="xl"
              />
              <Button
                colorScheme="blue"
                borderRadius="full"
                size="sm"
                onClick={updateCurrentOrganizationSettings}
              >
                Update Organization Settings
              </Button>
            </VStack>
          </FormControl>

          {/* 2) Create New Organization */}
          {/* <FormControl>
          
            <VStack align="start" spacing={0} mb={2}>
              <FormLabel fontWeight="semibold" mb={0}>
                Create New Organization
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={0}>
                Provision a brand-new organization in the system.
              </Text>
            </VStack>

          
            <VStack align="start" spacing={2} w="50%">
              <Input
                placeholder="New organization name"
                value={newOrganization}
                onChange={(e) => setNewOrganization(e.target.value)}
                fontSize="sm"
                borderRadius="xl"
              />
              <Input
                placeholder="Programs Allowed"
                value={modulesAllowedCreate}
                onChange={(e) => setModulesAllowedCreate(e.target.value)}
                fontSize="sm"
                borderRadius="xl"
              />
              <Input
                placeholder="Seat Count"
                value={seatCountCreate}
                onChange={(e) => setSeatCountCreate(e.target.value)}
                fontSize="sm"
                borderRadius="xl"
              />
              <Button
                colorScheme="blue"
                borderRadius="full"
                size="sm"
                onClick={handleNewOrganization}
              >
                Create Organization
              </Button>
            </VStack>
          </FormControl> */}

          {/* Delete Organization */}
          <FormControl>
            <VStack align="start" spacing={0} mb={2}>
              <FormLabel fontWeight="semibold" mb={0}>
                Delete Organization
              </FormLabel>
              <Text fontSize="sm" color="gray.500" mb={0}>
                Search for an organization by email and delete it permanently.
              </Text>
            </VStack>

            <VStack align="start" spacing={2} w="100%">
              <HStack w="100%" justifyContent="space-between">
                <Input
                  placeholder="Enter email to find organization"
                  value={deleteOrgEmail}
                  onChange={(e) => setDeleteOrgEmail(e.target.value)}
                  fontSize="sm"
                  borderRadius="xl"
                  w="50%"
                />

                <Text fontWeight="bold" mb={0}>
                  {organizationToDelete?.company_name}
                </Text>
              </HStack>
              <Button
                colorScheme="blue"
                borderRadius="full"
                size="sm"
                onClick={handleFetchOrganizationToDelete}
              >
                Find Organization
              </Button>
              {organizationToDelete && (
                <VStack align="start" spacing={2} w="100%">
                  {/* <Text fontSize="sm">
                    ID: {organizationToDelete.organization}
                  </Text> */}
                  <Button
                    colorScheme="red"
                    borderRadius="full"
                    size="sm"
                    onClick={openDeleteModal}
                  >
                    Delete Organization
                  </Button>
                </VStack>
              )}
            </VStack>
          </FormControl>
        </VStack>

        {/* Subscription Info */}
        <AdminSubscriptionInfo
          subscription={subscription}
          grantCreditAmount={grantCreditAmount}
          grantDays={grantDays} // Pass state
          setGrantDays={setGrantDays}
          setGrantCreditAmount={setGrantCreditAmount}
          revokeCreditAmount={revokeCreditAmount}
          setRevokeCreditAmount={setRevokeCreditAmount}
          handleGrant={handleGrant}
          handleRevoke={handleRevoke}
        />

        {/* Credentials (if needed) */}
        <VStack align="start" w="100%" spacing={2}>
          <Heading as="h2" size="md">
            Credentials
          </Heading>
          <Divider m={0} p={0} />
          <FormControl>
            <VStack align="start" spacing={0}>
              <FormLabel fontWeight="semibold" mb={0}>
                Update Credentials
              </FormLabel>
              <Text fontSize="sm" color="gray.500">
                Change organization details, such as name or domain.
              </Text>
              <Credentials />
            </VStack>
          </FormControl>
        </VStack>
      </VStack>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete the organization "
              {organizationToDelete?.company_name}".This action cannot be
              undone.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={handleDeleteOrganization}>
              Delete Organization
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AdminSettings;
