import React from "react";
import {
  VStack,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Text,
  Input,
  Button,
  HStack,
} from "@chakra-ui/react";

const AdminSubscriptionInfo = ({
  subscription,
  grantCreditAmount,
  setGrantCreditAmount,
  grantDays,
  setGrantDays,
  revokeCreditAmount,
  setRevokeCreditAmount,
  handleGrant,
  handleRevoke,
}) => {
  return (
    <VStack align="start" w="100%">
      <Box w="100%" borderBottom="1px solid" borderColor="blackAlpha.200">
        <Heading as="h2" size="md">
          Subscription
        </Heading>
      </Box>

      {/* Plan Type */}
      <FormControl display="flex" alignItems="center">
        <Box flex="1">
          <FormLabel mb="0" fontWeight="semibold">
            Plan Type
          </FormLabel>
          <Text fontSize="sm" color="gray.500">
            Which plan your organization is on
          </Text>
        </Box>
        <Text>{subscription?.plan_type}</Text>
      </FormControl>

      {/* Subscription Duration */}
      <FormControl display="flex" alignItems="center">
        <Box flex="1">
          <FormLabel mb="0" fontWeight="semibold">
            Duration
          </FormLabel>
          <Text fontSize="sm" color="gray.500">
            Subscription time span
          </Text>
        </Box>
        <Text>{subscription?.subscription_duration}</Text>
      </FormControl>

      {/* Seat Count */}
      <FormControl display="flex" alignItems="center">
        <Box flex="1">
          <FormLabel mb="0" fontWeight="semibold">
            Seat Count
          </FormLabel>
          <Text fontSize="sm" color="gray.500">
            Number of seats in your plan
          </Text>
        </Box>
        <Text>{subscription?.seat_count}</Text>
      </FormControl>

      {/* Monthly Credits */}
      <FormControl display="flex" alignItems="center">
        <Box flex="1">
          <FormLabel mb="0" fontWeight="semibold">
            Monthly Credits
          </FormLabel>
          <Text fontSize="sm" color="gray.500">
            How many credits you get per month
          </Text>
        </Box>
        <Text>{subscription?.monthly_credits}</Text>
      </FormControl>

      {/* Remaining Credits */}
      <FormControl display="flex" alignItems="center">
        <Box flex="1">
          <FormLabel mb="0" fontWeight="semibold">
            Remaining Credits
          </FormLabel>
          <Text fontSize="sm" color="gray.500">
            Available credits left this month
          </Text>
        </Box>
        <Text>{subscription?.remaining_credits}</Text>
      </FormControl>

      {/* Grant Credits */}
      <FormControl>
        <VStack align="start" spacing={2}>
        
          <FormLabel fontWeight="semibold" mb={0}>
            Grant Credits
          </FormLabel>
          <Text fontSize="sm" color="gray.500">
            Add credits to the organization's balance
          </Text>
          <HStack>
          <Input
            placeholder="Credits e.g. 100"
            value={grantCreditAmount}
            onChange={(e) => setGrantCreditAmount(e.target.value)}
            fontSize="sm"
            borderRadius="xl"
          />
          <Input
            placeholder="Days (≥ 0)"
            value={grantDays}
            onChange={(e) => setGrantDays(e.target.value)}
            fontSize="sm"
            borderRadius="xl"
            type="number"
          />
          </HStack>
          <Button
            colorScheme="blue"
            borderRadius="full"
            size="sm"
            onClick={handleGrant}
          >
            Grant
          </Button>
        </VStack>
      </FormControl>

      {/* Revoke Credits */}
      <FormControl>
        <VStack align="start" spacing={2}>
          <FormLabel fontWeight="semibold" mb={0}>
            Revoke Credits
          </FormLabel>
          <Text fontSize="sm" color="gray.500">
            Remove credits from the organization's balance
          </Text>
          <Input
            placeholder="e.g. 100"
            value={revokeCreditAmount}
            onChange={(e) => setRevokeCreditAmount(e.target.value)}
            fontSize="sm"
            borderRadius="xl"
            w="50%"
          />
          <Button
            colorScheme="red"
            borderRadius="full"
            size="sm"
            onClick={handleRevoke}
          >
            Revoke
          </Button>
        </VStack>
      </FormControl>
    </VStack>
  );
};

export default AdminSubscriptionInfo;
