import React from "react";
import { Box, Flex, Heading, Text, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const PaymentSuccessPage = () => {
  const history = useHistory();

  return (
    <Flex minH="100vh" align="center" justify="center" bg="#F7F7F7">
      <Box
        w="full"
        maxW="md"
        bg="white"
        p={{ base: 6, md: 8 }}
        boxShadow="lg"
        borderRadius="lg"
        textAlign="center"
      >
        <Heading size="lg" color="green.500">
          Payment Successful!
        </Heading>
        <Text mt={4} color="gray.600">
          Thank you for your payment. Your transaction was successful.
        </Text>
        <Button
          mt={6}
          colorScheme="blue"
          onClick={() => history.push("/login")}
        >
          Login
        </Button>
      </Box>
    </Flex>
  );
};

export default PaymentSuccessPage;