import React, { useState, useEffect } from "react";
import { Box, Select, useToast } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance"; // Import your Axios instance
import usePersistedState from "./../usePersistedState";

const UpdateOrganization = () => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = usePersistedState(
    "selectedOrganizationSettings",
    ""
  );
  const toast = useToast();

  // Fetch Organizations
  const fetchOrganizations = async () => {
    try {
      const response = await axiosInstance.get("/org/get_organizations");
      //console.log("fetchOrganizations response:", response);

      if (Array.isArray(response.data.data.organizations)) {
        setOrganizations(response.data.data.organizations);
      } else {
        console.error(
          "API did not return an array in 'organizations' key:",
          response.data
        );
        setOrganizations([]);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  // Fetch Current User Organization
  const fetchCurrentUserOrganization = async () => {
    try {
      const response = await axiosInstance.get("/org/get_current_organization");
      if (response.data?.data?.organization) {
        setSelectedOrganization(response.data.data.organization);
        localStorage.setItem("company_name", response.data.data.company_name);
      } else {
        setSelectedOrganization("");
      }
    } catch (error) {
      console.error("Error fetching current user's organization:", error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
    fetchCurrentUserOrganization();
  }, []);

  // Handle Organization Selection
  const handleOrganizationSelect = (newOrganization) => {
    if (newOrganization) {
      axiosInstance
        .post("/website_settings/update-organization", {
          new_organization: newOrganization,
        })
        .then((response) => {
          // Remove cache keys after update
          const keysToRemove = [
            "currentProgramUploadProgram",
            "showInputFieldUploadProgram",
            "isDropdownOpen",
            "selectedProgram",
            "programslist",
            "selectedOrganization",
            "selectedOrganizationSettings",
            "selectedTabIndex",
            "organization",
            "unsavedManualData",
          ];

          keysToRemove.forEach((key) => localStorage.removeItem(key));
          window.location.reload();

          toast({
            title: "Organization Updated",
            description: "Organization name updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          console.error("Error updating organization:", error);
        });
    } else {
      console.error("Please select an organization.");
    }
  };

  return (
    <Box>
      <Select
        w="150px"
        size="sm"
        borderRadius="full"
        borderColor="gray.300"
        placeholder="Select an org"
        value={selectedOrganization}
        onChange={(e) => {
          const selectedOrg = organizations.find(
            (org) => org.organization === e.target.value
          );
          if (selectedOrg) {
            setSelectedOrganization(selectedOrg.organization);
            handleOrganizationSelect(selectedOrg.organization);
          }
        }}
      >
        {organizations.map((org) => (
          <option key={org.organization} value={org.organization}>
            {org.company_name} {/* Only show company_name in the dropdown */}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default UpdateOrganization;
