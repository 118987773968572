import React, { useState } from "react";
import { HStack, Button, Select } from "@chakra-ui/react";

const Pagination = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
  fileCount,
  setFileCount,
}) => {
  const handleFileCountChange = (e) => {
    const value = parseInt(e.target.value, 9);
    setFileCount(value);
  };

  return (
    <HStack spacing={4} p={0} pr={4} justifyContent="right" mr={4} mb={2} mt={0} h="auto">
      <Button
        onClick={handlePrevPage}
        isDisabled={currentPage === 1}
        bg="#231F1F"
        color="#FFFFFF"
        _hover={{ bg: "#404040" }}
        borderRadius="full"
        size="sm"
        fontSize="sm"
      >
        Previous
      </Button>
      {/* <Text fontSize="xs" mb={0} color="black">
        Page {currentPage} of {totalPages}
      </Text> */}
      <Select
        value={fileCount}
        onChange={handleFileCountChange}
        size="sm"
        width="70px"
        fontSize="sm"
        borderRadius="full"
      >
        <option value={9}>9</option>
        <option value={15}>15</option>
        <option value={20}>20</option>
      </Select>
      <Button
        onClick={handleNextPage}
        isDisabled={currentPage === totalPages}
        bg="#231F1F"
        color="#FFFFFF"
        _hover={{ bg: "#404040" }}
        borderRadius="full"
        size="sm"
        fontSize="sm"
      >
        Next
      </Button>
    </HStack>
  );
};

export default Pagination;
