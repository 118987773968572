import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Flex,
  Text,
  HStack,
  Tooltip,
  useDisclosure,
  Button,
  keyframes,
  useToast,
  Switch,
  Icon,
  Spacer,
  Skeleton,
} from "@chakra-ui/react";
import { ChevronDownIcon, InfoIcon, LockIcon } from "@chakra-ui/icons";
import { AiOutlineHome } from "react-icons/ai";
import { FaWallet, FaBox, FaUser } from "react-icons/fa";
import { FiSettings, FiLogOut, FiCreditCard } from "react-icons/fi";
import {
  Link as RouterLink,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import { useAuth, logout } from "../auth";
import { useGoogleAuth } from "./common/google/GoogleAuthContext";
import { useUser } from "./userRole/UserContext";
import UpdateOrganization from "./websiteSettings/UpdateOrganization";
import PricingModal from "./PricingModal/PricingModal";
import upgradePlanIcon from "../assets/svg/upgrade_plan.svg"; // Import the SVG icon
import { getCurrentPlan } from "../api/subscriptionApi";
import VideoModal from "./VideoModal";

const dancingAnimation = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-5px); }
  50% { transform: translateY(0); }
  75% { transform: translateY(5px); }
  100% { transform: translateY(0); }
`;

const dotFlashing = keyframes`
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
`;

const DotLoader = () => (
  <HStack spacing={1} mb="18px">
    <Box
      as="span"
      w="6px"
      h="6px"
      bg="gray.300"
      borderRadius="full"
      animation={`${dotFlashing} 1s infinite`}
    />
    <Box
      as="span"
      w="6px"
      h="6px"
      bg="gray.300"
      borderRadius="full"
      animation={`${dotFlashing} 1s infinite 0.2s`}
    />
    <Box
      as="span"
      w="6px"
      h="6px"
      bg="gray.300"
      borderRadius="full"
      animation={`${dotFlashing} 1s infinite 0.4s`}
    />
  </HStack>
);

const UserProfileMenu = () => {
  const history = useHistory();
  const toast = useToast();
  const [logged] = useAuth();
  const { authState } = useGoogleAuth();
  const { user, loading } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isUserLoggedIn = logged || authState.isLoggedIn;
  const [currentPlan, setCurrentPlan] = useState(null);
  const [showAnimation, setShowAnimation] = useState(true);
  const location = useLocation();
  const isProgramRoute = location.pathname === "/program";

  const {
    isOpen: isVideoOpen,
    onOpen: onVideoOpen,
    onClose: onVideoClose,
  } = useDisclosure();
  const videoId = "rQV28uV5klg"; // Replace with your actual YouTube video ID

  useEffect(() => {
    const timer = setTimeout(() => setShowAnimation(false), 3000); // Stop animation after 3 seconds
    return () => clearTimeout(timer);
  }, []);

  // Basic info from localStorage / user object
  const username = localStorage.getItem("username") || "Unknown User";
  const email = localStorage.getItem("email") || "unknown@example.com";

  // Extract plan & credits from the user object (or from localStorage)
  const remainingCredits = currentPlan?.remaining_credits ?? 0;
  const monthlyCredits = currentPlan?.monthly_credits ?? 0;
  const planType = currentPlan?.plan_type ?? "free"; // default to "free"
  const subscriptionDuration = currentPlan?.subscription_duration ?? "monthly";
  const seatCount = currentPlan?.seat_count ?? 0;

  // Example data from your response

  const subscription_expiration_date =
    currentPlan?.subscription_expiration_date;

  // 1. Convert strings to Date objects
  const expirationDate = new Date(subscription_expiration_date);

  // 2. Get today's date (local time)
  const today = new Date();

  // 3. Calculate the difference in milliseconds
  const diffInMs = expirationDate - today;

  // 4. Convert to days (round up so partial days count as a full day)
  const daysRemaining = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  // 5. (Optional) If daysRemaining is negative, subscription is expired
  const displayDaysRemaining = daysRemaining > 0 ? daysRemaining : "XX";

  // 6. Output
  // console.log("Days Remaining:", displayDaysRemaining);

  const role = user ? user.role : null;

  const logoutAndClear = () => {
    logout();
    sessionStorage.removeItem("popupClosedForSession");
    const keysToRemove = [
      "email",
      "username",
      "currentProgramUploadProgram",
      "showInputFieldUploadProgram",
      "isDropdownOpen",
      "selectedProgram",
      "programslist",
      "selectedOrganization",
      "selectedOrganizationSettings",
      "selectedTabIndex",
      "organization",
      "unsavedManualData",
      "company_name",
      "selectedOrganizationId",
      "selectedOrganizationName",
      "contentType",
    ];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    history.push("/login");
  };

  let popoverLinks = [];
  if (loading) {
    // Optionally handle a loading state
  } else if (isUserLoggedIn) {
    popoverLinks = [
      ...(role === "Admin"
        ? [{ to: "/admin_panel", text: "Users", icon: <LockIcon /> }]
        : []),
      // ...(role === "Admin"
      //   ? [
      //       {
      //         to: "/website_settings",
      //         text: "Website Setting",
      //         icon: <FiSettings />,
      //       },
      //     ]
      //   : []),
      ...(role === "Admin" || role === "Manager" || role === "Employee"
        ? [
            {
              to: "/settings",
              text: "Settings",
              icon: <FiSettings />,
            },
          ]
        : []),
      ...(role === "Manager"
        ? [{ to: "/manager_panel", text: "Users", icon: <LockIcon /> }]
        : []),
      ...(role === "Admin" || role === "Manager" || role === "Employee"
        ? [
            {
              to: "/sender_information",
              text: "Sender Profile",
              icon: <InfoIcon />,
            },
            {
              to: "/outreach_profiles",
              text: "Product Profile",
              icon: <LockIcon />,
            },
          ]
        : []),
      ...(role === "Admin" || role === "Manager" || role === "Employee"
        ? [
            {
              to: "/settings/billing",
              text: "Billing",
              icon: <FiCreditCard />,
            },
          ]
        : []),
    ];
  }

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const data = await getCurrentPlan();
        // console.log("response", data);
        setCurrentPlan(data.data);
      } catch (error) {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
      }
    };
    fetchPlan();
  }, [toast]);

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      // spacing={3}
      width={isProgramRoute ? "100%" : "auto"}
    >
      {isProgramRoute && (
        <HStack>
          {/* Wallet-like icon + Plan Info */}
          <Flex align="center" gap={2}>
            <Icon as={FaWallet} boxSize={5} color="blackAlpha.700" />
            <Box>
              <Text fontSize="md" fontWeight="bold" mb={0}>
                {planType.charAt(0).toUpperCase() + planType.slice(1)} (
                {subscriptionDuration.charAt(0).toUpperCase() +
                  subscriptionDuration.slice(1)}
                )
              </Text>

              <Text fontSize="xs" color="gray.500" mb={0}>
                My Plan
              </Text>
            </Box>
          </Flex>

          {/* Another spacer if desired */}
          <Box
            height="28px"
            borderLeft="1px solid"
            borderColor="gray.300"
            mx={3}
          />

          {/* Folder-like icon + Credits Info */}
          <Flex align="center" justifyContent="center" gap={2}>
            <Icon as={FaBox} boxSize={5} color="blackAlpha.700" />
            <Box
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text fontSize="md" fontWeight="bold" mb={0}>
                {currentPlan ? remainingCredits : <DotLoader />}
                {/* / {monthlyCredits} */}
              </Text>
              <Text fontSize="xs" color="gray.500" mb={0}>
                Credits expire in {displayDaysRemaining} days
              </Text>
            </Box>
          </Flex>

          <Box
            height="28px"
            borderLeft="1px solid"
            borderColor="gray.300"
            mx={3}
          />
          {/* Wallet-like icon + Seat Count */}
          <Flex align="center" justifyContent="center" gap={2}>
            <Icon as={FaUser} boxSize={5} color="blackAlpha.700" />
            <Box
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Text fontSize="md" fontWeight="bold" mb={0}>
                {currentPlan ? seatCount : <DotLoader />}
              </Text>
              <Text fontSize="xs" color="gray.500" mb={0}>
                Seat Count
              </Text>
            </Box>
          </Flex>

          {/* Another spacer if desired */}
          <Box
            height="28px"
            borderLeft="1px solid"
            borderColor="gray.300"
            mx={3}
          />

          {isProgramRoute && (role === "Admin" || role === "Manager") && (
            <Button
              onClick={onOpen}
              bg="white"
              color="black"
              _hover="none"
              _active="none"
              border="1px solid"
              borderColor="gray.200"
              boxShadow="md"
              size="sm"
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              px={4}
              gap={2}
              animation={
                showAnimation ? `${dancingAnimation} 1s infinite` : "none"
              } // Apply animation
            >
              Upgrade
              <Box as="span" display="inline-flex">
                <img
                  src={upgradePlanIcon}
                  alt="Upgrade Plan"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Box>
            </Button>
          )}
          <Button
            onClick={onVideoOpen}
            color="white"
            bg="black"
            _hover="none"
            _active="none"
            // border="1px solid"
            // borderColor="white.200"
            boxShadow="md"
            size="sm"
            borderRadius="full"
            // display="flex"
            // alignItems="center"
            // justifyContent="space-between"
          >
            Watch Demo
          </Button>
        </HStack>
      )}

      <HStack>
        {role === "Admin" && <UpdateOrganization />}
        <HStack
          bg="white"
          borderRadius="full"
          p={1}
          py={0}
          spacing={1}
          mr={3}
          border="1px solid"
          borderColor="gray.200"
          boxShadow="md"
        >
          <Avatar
            name={username}
            src="https://bit.ly/broken-link"
            cursor="pointer"
            size="xs"
            mr={0}
          />
          <Menu>
            <MenuButton
              as={Button}
              size="sm"
              bg="transparent"
              _hover="white"
              _focus="white"
              _active="none"
              rightIcon={<ChevronDownIcon />}
              borderRadius="full"
              pr={1}
              pl={1}
            >
              <Text mb={0} bg="transparent" _hover="white" _focus="white">
                {username}
              </Text>
            </MenuButton>

            <MenuList borderRadius="lg" boxShadow="lg" p={2}>
              <Box textAlign="center" p={4} pb={0}>
                <Flex>
                  <Avatar
                    name={username}
                    src="https://bit.ly/broken-link"
                    size="lg"
                    mb={2}
                  />
                  <Box ml={4} align="left" pt={2} pb={2}>
                    <Text fontWeight="bold" mb={0}>
                      {username}
                    </Text>
                    <Text fontSize="xs" color="gray.500">
                      {email}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <MenuDivider />

              {popoverLinks.map((link, index) => (
                <MenuItem
                  as={RouterLink}
                  to={link.to}
                  key={index}
                  icon={link.icon}
                  borderRadius="md"
                  _hover={{ bg: "gray.100" }}
                  _focus={{ bg: "gray.100" }}
                >
                  {link.text}
                </MenuItem>
              ))}

              <MenuDivider />
              <MenuItem
                onClick={logoutAndClear}
                icon={<FiLogOut />}
                borderRadius="md"
                _hover={{ bg: "gray.100" }}
                _focus={{ bg: "gray.100" }}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </HStack>

      <PricingModal isOpen={isOpen} onClose={onClose} />
      <VideoModal
        isOpen={isVideoOpen}
        onClose={onVideoClose}
        videoId={videoId}
      />
    </HStack>
  );
};

export default UserProfileMenu;
