import React from "react";
import {
  Box,
  Image,
  Text,
  Heading,
  VStack,
  keyframes,
  useBreakpointValue,
} from "@chakra-ui/react";
import opsUIImage from "../../assets/images/ops-ui.png"; // Path for horizontal image
import opsUIVerticalImage from "../../assets/images/ops-ui-vertical.png"; // Path for vertical image

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translateY(20px); /* Start from slightly below */
}
to {
  opacity: 1;
  transform: translateY(0); /* Move to original position */
}
`;

const OpsUISection = () => {
  const imageSrc = useBreakpointValue({
    base: opsUIVerticalImage, // Use vertical image for smaller screens
    md: opsUIImage, // Use horizontal image for medium and larger screens
  });

  return (
    <Box px={8} bg ="white">
    <Box
      as="section"
      display="flex"
      flexDirection="column"
      alignItems="center"
      px={{ base: 4, md: 16 }}
      py={{ base: 8, md: 16 }}
      bg="#F8F9F7"
      borderRadius="30px"
      w="100%"
    >
      {/* Text and Heading */}
      <VStack spacing={2} textAlign="center" mb={28}>
        <Text fontSize={{ base: "14px", md: "16px" }} color="gray.600" mb={0}>
          How it works
        </Text>
        <Heading
          fontSize={{ base: "24px", md: "40px" }}
          fontWeight="medium"
          color="#08072B"
          mb={0}
        >
          Multiple AI engines and proven prompts combined in a simple formula
        </Heading>
      </VStack>

      {/* Image */}
      <Image
        src={imageSrc}
        alt="How it works - The ops UI on top of your data"
        maxW="100%"
        objectFit="contain"
        animation={`${fadeInUp} 1s ease-out`}
      />
    </Box>
    </Box>
  );
};

export default OpsUISection;
