import React, { useEffect } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { FiZap } from "react-icons/fi";

const EnterpriseCard = () => {
  const highlightCardBg = useColorModeValue("#08072B", "green.900");
  const highlightTextColor = useColorModeValue("white", "white");

  useEffect(() => {
    const loadCalendlyScript = () => {
      const script = document.createElement("script");
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        console.log("Calendly script loaded!");
      };
    };

    // Load Calendly script if it's not already loaded
    if (!window.Calendly) {
      loadCalendlyScript();
    }
  }, []);

  const openCalendly = () => {
    if (window.Calendly) {
      // Disable body scroll
      document.body.style.overflow = "hidden";

      window.Calendly.initPopupWidget({
        url: "https://calendly.com/bryan-smart-focus",
      });

      // Apply custom styles to Calendly modal
      const checkCalendlyOpen = setInterval(() => {
        const calendlyPopup = document.querySelector(".calendly-popup");
        if (calendlyPopup) {
          calendlyPopup.style.height = "100vh"; // Set height
          calendlyPopup.style.backgroundColor = "white"; // Change background color
          calendlyPopup.style.borderRadius = "12px"; // Optional: rounded corners
          calendlyPopup.style.boxShadow = "0px 10px 30px rgba(0,0,0,0.2)"; // Optional: Add shadow
          calendlyPopup.style.overflowY = "hidden";
          clearInterval(checkCalendlyOpen);
        }
      }, 500);

      // Restore scrolling when Calendly closes
      const checkCalendlyClose = setInterval(() => {
        if (!document.querySelector(".calendly-popup")) {
          document.body.style.overflowY = "auto"; // Re-enable scrolling
          clearInterval(checkCalendlyClose);
        }
      }, 500);
    } else {
      console.error("Calendly script not loaded yet");
    }
  };

  return (
    <Box textAlign="left">
      <VStack align="flex-start" spacing={3}>
        <Box
          bg={highlightCardBg}
          color={highlightTextColor}
          border="1px solid"
          borderColor="blackAlpha.300"
          boxShadow="lg"
          rounded="xl"
          p={6}
        >
          <Icon as={FiZap} boxSize={8} color="green.400" mb={4} />
          <Heading size="md" fontWeight="bold" mb={0}>
            Enterprise
          </Heading>
          <Box display="flex" alignItems="baseline">
            <Text fontSize="4xl" fontWeight="bold" mr={1} mb={0}>
              Custom
            </Text>
          </Box>
          <Box display="flex" alignItems="baseline" mb={2}>
            <Text fontSize="1xl" fontWeight="bold" mr={1} mb={0}>
              Fully customizable
            </Text>
          </Box>
          <Text color="whiteAlpha.900">
            Tailored for organizations with unique requirements or high-volume
            needs. Offers advanced analytics, customizable options, and
            dedicated support.
          </Text>
          <Button
            mt={4}
            colorScheme="gray"
            bg="white"
            color="#08072B"
            _hover="none"
            _active="none"
            rounded="full"
            w="full"
            onClick={openCalendly} // Opens Calendly modal
          >
            Contact us
          </Button>
        </Box>
        <Box mt={4}>
          <Text fontWeight="semibold" mb={2}>
            What’s included
          </Text>
          <List spacing={2} pl={0}>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="lime.400" />
              Fully customizable options
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="lime.400" />
              Dedicated technical support
            </ListItem>
            <ListItem>
              <ListIcon as={CheckCircleIcon} color="lime.400" />
              Advanced analytics & 24/7 priority support
            </ListItem>
          </List>
        </Box>
      </VStack>
    </Box>
  );
};

export default EnterpriseCard;
