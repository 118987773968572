import React, { useState, useEffect } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  SimpleGrid,
  useToast,
  useColorModeValue,
  Switch,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  List,
  ListItem,
  ListIcon,
  Icon,
  HStack,
  VStack,
  Heading,
  Tag,
  Button,
  Select,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { FiZap } from "react-icons/fi";
import BillingToggle from "./BillingToggle";
import PlanCard from "./PlanCard";
import EnterpriseCard from "./EnterpriseCard";
import { updateSubscription, getCurrentPlan } from "../../api/subscriptionApi";
import { getAutoRenewStatus, toggleAutoRenew } from "../../api/stripeApi";
import { getCurrencyData } from "../../utils/currencyUtils";

const PricingModal = ({ isOpen, onClose }) => {
  const history = useHistory();
  const [isAnnual, setIsAnnual] = useState(true);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState({
    planType: null,
    subscriptionDuration: null,
  });

  const [seatCount, setSeatCount] = useState(5); // Seat count for Teams plan
  // const [autoRenew, setAutoRenew] = useState(false);
  // State for redirect modal
  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const toast = useToast();

  const incrementSeats = () => setSeatCount((prev) => Math.min(prev + 1, 100));
  const decrementSeats = () => setSeatCount((prev) => Math.max(prev - 1, 5));

  const fetchPlan = async () => {
    try {
      const data = await getCurrentPlan();
      setCurrentPlan(data.data);
      if (data.data.plan_type.toLowerCase() === "teams") {
        setSeatCount(data.data.seat_count);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlan();
  }, [toast]);

  // 2) Track selected currency (default "US", for example)
  const [currency, setCurrency] = useState("US");

  // Use the helper function to get prices based on current state
  const {
    symbol,
    individualMonthlyPrice,
    individualAnnualPrice,
    teamsMonthlyPricePerSeat,
    teamsAnnualPricePerSeat,
    TotalteamsAnnualPricePerSeat,
    TotalindividualAnnualPrice,
    teamsMonthlyPrice,
  } = getCurrencyData(currency, seatCount, isAnnual);

  const billingPeriod = isAnnual ? "year" : "month";

  const handleUpgrade = async (planType, seats = null) => {
    setLoadingButton({
      planType: planType.toLowerCase(),
      subscriptionDuration: billingPeriod === "month" ? "monthly" : "annual",
    });

    try {
      const response = await updateSubscription(planType, billingPeriod, seats);
      // console.log("handleUpgrade response", response);

      if (response && response.redirect_url) {
        setModalData({
          title: "Action Required",
          message: response.message,
          buttonLabel: "Go to User Panel",
          redirectUrl: response.redirect_url,
        });
        setIsRedirectModalOpen(true);
      } else if (response.data && response.data.session_url) {
        // Redirect to session_url if it exists
        window.open(response.data.session_url, "_blank");
      } else {
        toast({
          title: "Subscription Updated",
          description:
            response.message || "Your subscription has been updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }

      fetchPlan();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      // Clear loading state once the request is done
      setLoadingButton({ planType: null, subscriptionDuration: null });
    }
  };

  const plans = [
    {
      title: "Free",
      price: `${symbol}0`,
      period: billingPeriod,
      credits: "30",
      description:
        "Ideal for personal projects or trying out our service completely free. Includes basic support and up to 5 personalized pieces of content per prospect or company.",
      features: [
        "30 Credits Per Month",
        "Basic Support",
        "Upto 5 pieces of content per prospect or company",
      ],
      bg: useColorModeValue("white", "gray.800"),
      buttonbg: "white",
      color: "black",
      icon: FiZap,
    },
    {
      title: "Individual",
      price: isAnnual
        ? `${symbol}${individualAnnualPrice}`
        : `${symbol}${individualMonthlyPrice}`,
      period: billingPeriod,
      credits: "750",
      description:
        "Best for freelancers or professionals looking to secure executive-level meetings with personalized outreach content, tailored for bespoke campaigns.",
      features: [
        "750 Credits Per Month",
        "Monthly roll over of unused credits until cancelled",
        "Premium Support",
        "Upto 5 pieces of content per prospect or company",
      ],
      bg: useColorModeValue("white", "gray.800"),
      buttonbg: "#08072B",
      color: "whiteAlpha.900",
      icon: FiZap,
      originalPrice: isAnnual ? `${symbol}${individualMonthlyPrice}` : null,
    },
    {
      title: "Teams",
      // Displaying the discounted price per seat to emphasize savings
      price: isAnnual
        ? `${symbol}${teamsAnnualPricePerSeat}`
        : `${symbol}${teamsMonthlyPricePerSeat}`,
      period: ` seat / ${billingPeriod}`,
      credits: "1000",
      description:
        "Perfect for businesses or teams aiming to achieve executive-level meetings with advanced outreach. Includes pooled credits and premium support.",
      features: [
        "1000 Credits Per Month per seat",
        "Monthly roll over of unused credits until cancelled",
        "Premium Support",
        "Credits pooled across the team",
        "Upto 5 pieces of content per prospect or company",
      ],
      bg: useColorModeValue("white", "gray.800"),
      buttonbg: "white",
      color: "black",
      icon: FiZap,
      seatIncrement: incrementSeats,
      seatDecrement: decrementSeats,
      seatCount,
      // Show original per-seat cost for comparison (Individual plan cost)
      originalPrice: isAnnual
        ? `${symbol}${individualMonthlyPrice}`
        : `${symbol}${individualMonthlyPrice}`,
    },
  ];

  const closeRedirectModal = () => {
    setIsRedirectModalOpen(false);
    setModalData(null);
  };

  const handleRedirect = () => {
    if (modalData?.redirectUrl) {
      window.location.href = modalData.redirectUrl;
      closeRedirectModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "full", md: "md", lg: "6xl" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="xl"
          h="90vh"
          overflow="auto"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          pt={8}
        >
          <ModalHeader
            textAlign="center"
            fontSize="2xl"
            fontWeight="bold"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            Pricing Plans
            {/* <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              w="auto"
              size="sm"
              borderRadius="lg"
              ml={2}
            >
              <option value="US">$ USD</option>
              <option value="UK">£ GBP</option>
              <option value="EU">€ EUR</option>
            </Select> */}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6}>
            <Text color="gray.800" fontSize="md" textAlign="center" mb={4}>
              Our pricing plans are simple and designed to cater to various
              needs. Choose a plan that suits you best.
            </Text>

            <Alert
              // status="info"
              // variant="left-accent"
              rounded="md"
              mb={4}
              // bg="gray.100"
              bg="white"
              // boxShadow="md"
              // border="1px solid"
              // borderColor="blue.300"
              justifyContent="center"
              textAlign="center"
            >
              <AlertDescription
                fontSize="sm"
                color="gray.800"
                textAlign="center"
              >
                {/* Credit explanation */}
                <Box mb={1}>
                  <Text as="span" fontWeight="bold" color="black" mb={0}>
                    1 Credit = 1 Company or 1 Senior Exec Prospect
                  </Text>{" "}
                </Box>

                {/* Outreach Tools Subheading */}
                <Text fontWeight="bold" mb={1}>
                  Included with Each Credit
                </Text>

                {/* Tags */}
                <HStack spacing={2} wrap="wrap" justifyContent="center">
                  {/* Email Items (blue) */}

                  <Tag
                    colorScheme="blue"
                    size="sm"
                    fontWeight="bold"
                    borderRadius="full"
                  >
                    Initial email
                  </Tag>
                  <Tag
                    colorScheme="blue"
                    size="sm"
                    fontWeight="bold"
                    borderRadius="full"
                  >
                    Follow-up email
                  </Tag>
                  {/* LinkedIn Items (green) */}
                  <Tag
                    colorScheme="green"
                    size="sm"
                    fontWeight="bold"
                    borderRadius="full"
                  >
                    LinkedIn connection note
                  </Tag>
                  <Tag
                    colorScheme="green"
                    size="sm"
                    fontWeight="bold"
                    borderRadius="full"
                  >
                    LinkedIn message
                  </Tag>

                  {/* Cold-calling Script (orange) */}
                  <Tag
                    colorScheme="orange"
                    size="sm"
                    fontWeight="bold"
                    borderRadius="full"
                  >
                    Cold-calling script
                  </Tag>
                </HStack>
              </AlertDescription>
            </Alert>

            <BillingToggle
              isAnnual={isAnnual}
              setIsAnnual={setIsAnnual}
              bg="gray.200"
              activeBg="white"
            />
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={2}>
              {plans.map((plan, index) => {
                const isCurrentPlan =
                  currentPlan?.plan_type?.toLowerCase() ===
                    plan.title.toLowerCase() &&
                  ((currentPlan?.subscription_duration === "monthly" &&
                    billingPeriod === "month") ||
                    (currentPlan?.subscription_duration === "annual" &&
                      billingPeriod === "year")) &&
                  (plan.title !== "Teams" ||
                    currentPlan?.seat_count === seatCount);

                let buttonText = isCurrentPlan
                  ? "Your current plan"
                  : `Get ${plan.title}`;

                // Determine if this button should be in a loading state
                const buttonLoading =
                  loadingButton.planType === plan.title.toLowerCase() &&
                  loadingButton.subscriptionDuration ===
                    (billingPeriod === "month" ? "monthly" : "annual");

                return (
                  <PlanCard
                    key={index}
                    {...plan}
                    onClick={
                      isCurrentPlan
                        ? undefined
                        : () =>
                            plan.title === "Teams"
                              ? handleUpgrade(
                                  plan.title.toLowerCase(),
                                  seatCount
                                )
                              : handleUpgrade(plan.title.toLowerCase())
                    }
                    isDisabled={isCurrentPlan} // Only disable button for the current plan
                    buttonText={buttonText}
                    plan={plan}
                    style={{
                      filter:
                        isAnnual && plan.title === "Free"
                          ? "blur(3px)"
                          : "none",
                    }}
                    isAnnual={isAnnual}
                    isLoading={buttonLoading}
                    symbol={symbol}
                    TotalteamsAnnualPricePerSeat={TotalteamsAnnualPricePerSeat}
                    TotalindividualAnnualPrice={TotalindividualAnnualPrice}
                    teamsMonthlyPrice={teamsMonthlyPrice}
                  />
                );
              })}
              {/* Enterprise Card */}
              <EnterpriseCard
                onContact={() =>
                  toast({
                    title: "Coming Soon",
                    description: "Enterprise plan is Coming Soon.",
                    status: "info",
                    duration: 5000,
                    isClosable: true,
                  })
                }
              />
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>

      {modalData && (
        <Modal
          isOpen={isRedirectModalOpen}
          onClose={closeRedirectModal}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{modalData.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>{modalData.message}</Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="gray"
                bg="#08072B"
                color="white"
                _hover="none"
                _active="none"
                rounded="full"
                onClick={handleRedirect}
              >
                {modalData.buttonLabel}
              </Button>
              <Button
                colorScheme="gray"
                bg="white"
                color="#08072B"
                border="1px solid"
                borderColor="#08072B"
                _hover="none"
                _active="none"
                rounded="full"
                ml={3}
                onClick={closeRedirectModal}
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default PricingModal;
