import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Heading,
  IconButton,
  Text,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useColorMode,
  Tooltip,
} from "@chakra-ui/react";
import { useHistory, Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { FiArrowLeft } from "react-icons/fi";
import axiosInstance from "../../helpers/axiosInstance";
import UserProfileMenu from "../UserProfileMenu";
import ViewDetailsPagination from "../Program/MiddlePannel/Generation/ViewDetailsPagination";
import AddProfileModal from "./AddProfileModal";
import { useUser } from "../userRole/UserContext";
import ReactMarkdown from "react-markdown";

function OutreachProfiles() {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const toast = useToast();
  const isHTML = (text) => /<\/?[a-z][\s\S]*>/i.test(text);
  const [profiles, setProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileCount, setFileCount] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const { user } = useUser();
  const role = user ? user.role : null;

  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      const response = await axiosInstance.get("/profile/");
      if (response.status === 200 && response.data.success) {
        setProfiles(response.data.data.profiles || []); // Ensure profiles is always an array
        const calculatedTotalPages = Math.ceil(
          (response.data.data.profiles || []).length / fileCount
        );
        setTotalPages(calculatedTotalPages);
      }
    } catch (error) {
      console.error("Error fetching profiles:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching profiles.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleDeleteProfile = async () => {
    if (profileToDelete) {
      try {
        const response = await axiosInstance.delete(
          `/profile/${profileToDelete._id}`
        );
        if (response.status === 200) {
          toast({
            title: "Profile Deleted",
            description: response.data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          setProfiles((prevProfiles) =>
            prevProfiles.filter(
              (profile) => profile._id !== profileToDelete._id
            )
          );
        }
      } catch (error) {
        console.error("Error deleting profile:", error);
        toast({
          title: "Error",
          description: "An error occurred while deleting the profile.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
      setIsDeleteDialogOpen(false);
    }
  };

  const confirmDeleteProfile = (profile) => {
    setProfileToDelete(profile);
    setIsDeleteDialogOpen(true);
  };

  const handleEditProfile = async (profile) => {
    try {
      const response = await axiosInstance.get(`/profile/${profile._id}`);
      if (response.status === 200 && response.data.success) {
        setSelectedProfile(response.data.data);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching the profile.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const onOpenModal = () => {
    setSelectedProfile(null); // Reset selectedProfile to null for adding a new profile
    setIsModalOpen(true);
  };

  const onCloseModal = () => setIsModalOpen(false);

  const handleSave = () => {
    fetchProfiles(); // Refresh profiles after saving
  };

  const goToHomePage = () => {
    history.push("/program");
  };

  return (
    <Box
      width={{ base: "100%", md: "100%" }}
      height="100vh"
      display="flex"
      alignItems="top"
      justifyContent="top"
    >
      <Box
        direction="column"
        height="100vh"
        width={{ base: "100%", md: "100%" }}
      >
        <Box
          // width={{ base: "100%", md: "100%" }}
          height="15vh"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          pt={10}
          pl={6}
          pr={5}
        >
          <HStack spacing={6} ml={4}>
            {/* <IconButton
              icon={<FiArrowLeft />}
              bg="transparent"
              onClick={() => history.goBack()}
              aria-label="Back"
              _hover="none"
              size="lg"
            /> */}
            {/* <Tooltip
              label="Home Page"
              bg="white"
              color="black"
              border="1px"
              borderColor="gray.200"
            > */}
              <Link to="/program">
                <AiOutlineHome cursor="pointer" size={24} />
              </Link>
            {/* </Tooltip> */}
            <Heading fontWeight="bold" mb="0" color="black">
              Product or Proposition Profiles
            </Heading>
          </HStack>
          <HStack spacing={2}>
            {role !== "Employee" && (
              <Button
                bg="#231F1F"
                color="#FFFFFF"
                _hover={{ bg: "#404040" }}
                borderRadius="full"
                size="sm"
                // mr={3}
                onClick={onOpenModal}
              >
                Add Profile
              </Button>
            )}
            <UserProfileMenu />
          </HStack>
        </Box>
        <Box p={5} pl={8} pr={8} pb={2} h="80vh">
          <Box
            width={{ base: "100%", md: "100%" }}
            h="100%"
            bg="white"
            borderRadius="xl"
            p={3}
            pb={1}
            borderColor="blackAlpha.300"
            borderWidth="1px"
          >
            <Box h="100%" overflowY="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>Profile Name</Th>
                    <Th>Company Product or Proposition</Th>
                    {/* <Th>Keywords</Th> */}
                    <Th>Key Selling Point (USP)</Th>
                    <Th>Case Study</Th>
                    {role !== "Employee" && <Th pl={0}>Actions</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {profiles
                    .slice(
                      (currentPage - 1) * fileCount,
                      currentPage * fileCount
                    )
                    .map((profile) => (
                      <Tr key={profile._id}>
                        <Td>{profile.outreach_profile_name}</Td>
                        {/* <Td>{profile.sender_company_product_information}</Td> */}
                        <Td textAlign="top">
                          {isHTML(profile.sender_company_product_information) ? (
                            // Render HTML safely if it contains HTML tags
                            <Text
                              whiteSpace="pre-wrap"
                              dangerouslySetInnerHTML={{
                                __html: profile.sender_company_product_information,
                              }}
                            />
                          ) : (
                            // Render as Markdown if it doesn't contain HTML
                            <ReactMarkdown>{profile.sender_company_product_information}</ReactMarkdown>
                          )}
                        </Td>
                        {/* <Td>
                          {profile.in_the_news_search_term
                            ? profile.in_the_news_search_term.join(", ")
                            : ""}
                        </Td> */}
                        {/* <Td>{profile.key_usp}</Td> */}
                        <Td textAlign="top">
                          {isHTML(profile.key_usp) ? (
                            // Render HTML safely if it contains HTML tags
                            <Text
                              whiteSpace="pre-wrap"
                              dangerouslySetInnerHTML={{
                                __html: profile.key_usp,
                              }}
                            />
                          ) : (
                            // Render as Markdown if it doesn't contain HTML
                            <ReactMarkdown>{profile.key_usp}</ReactMarkdown>
                          )}
                        </Td>
                        <Td textAlign="top">
                          {isHTML(profile.case_study) ? (
                            // Render HTML safely if it contains HTML tags
                            <Text
                              whiteSpace="pre-wrap"
                              dangerouslySetInnerHTML={{
                                __html: profile.case_study,
                              }}
                            />
                          ) : (
                            // Render as Markdown if it doesn't contain HTML
                            <ReactMarkdown>{profile.case_study}</ReactMarkdown>
                          )}
                        </Td>

                        {role !== "Employee" && (
                          <Td pl={0}>
                            <Flex>
                              <IconButton
                                icon={<EditIcon />}
                                colorScheme="blue"
                                aria-label="Edit Profile"
                                borderRadius="full"
                                size="sm"
                                onClick={() => handleEditProfile(profile)}
                              />
                              <IconButton
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                aria-label="Delete Profile"
                                borderRadius="full"
                                size="sm"
                                onClick={() => confirmDeleteProfile(profile)}
                                ml={2}
                              />
                            </Flex>
                          </Td>
                        )}
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
          <Flex justifyContent="right" mt={1}>
            <ViewDetailsPagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
              fileCount={fileCount}
              setFileCount={setFileCount}
            />
          </Flex>
        </Box>

        {/* Add/Edit Profile Modal */}
        <AddProfileModal
          isOpen={isModalOpen}
          onClose={onCloseModal}
          profileData={selectedProfile} // Pass the profile data for editing
          onSave={handleSave} // Refresh profiles after saving
        />

        {/* Delete Confirmation Dialog */}
        <AlertDialog
          isOpen={isDeleteDialogOpen}
          leastDestructiveRef={null}
          onClose={() => setIsDeleteDialogOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Profile
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to delete the profile? This action cannot
                be undone.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsDeleteDialogOpen(false)}
                >
                  No
                </Button>
                <Button colorScheme="red" onClick={handleDeleteProfile} ml={3}>
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
}

export default OutreachProfiles;
