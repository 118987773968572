import axiosInstance from "../helpers/axiosInstance";

// API endpoint for updating subscription
export const updateSubscription = async (
  planType,
  billingPeriod = "monthly",
  seatCount = null
) => {
  // Map 'yearly' to 'annual' and 'month' to 'monthly'
  const formattedBillingPeriod =
    billingPeriod === "year"
      ? "annual"
      : billingPeriod === "month"
      ? "monthly"
      : billingPeriod;

  const payload = {
    plan_type: planType,
    subscription_duration: formattedBillingPeriod,
    seat_count: seatCount,
  };

  // console.log("payload", payload);

  try {
    const response = await axiosInstance.post(
      "/subscription/update-subscription",
      payload
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to update subscription"
    );
  }
};

// API endpoint for getting current subscription
export const getCurrentPlan = async () => {
  try {
    const response = await axiosInstance.get(
      "/subscription/get-subscription-info"
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to fetch current plan"
    );
  }
};

// Revoke Credits
export const revokeCredits = async (creditAmount) => {
  const response = await axiosInstance.post("/subscription/revoke-credits", {
    credit_amount: creditAmount,
  });
  return response.data; // e.g. { success: true, message: "...", code: 200 }
};

// Grant Credits
export const grantCredits = async (creditAmount, grantDays) => {
  const response = await axiosInstance.post("/subscription/grant-credits", {
    credit_amount: creditAmount,
    days: grantDays,
  });
  return response.data; // e.g. { success: true, message: "...", code: 200 }
};
