import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Skeleton,
  useColorModeValue,
} from "@chakra-ui/react";

const VideoModal = ({ isOpen, onClose, videoId }) => {
  const [loading, setLoading] = useState(true);
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  const modalBg = useColorModeValue("white", "gray.800");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg={modalBg}>
        <ModalHeader>Demo Video</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {loading && <Skeleton height="315px" borderRadius="lg" />}
          <Box
            as="iframe"
            width="100%"
            height="315px"
            src={embedUrl}
            frameBorder="0"
            borderRadius="lg"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            onLoad={() => setLoading(false)}
            display={loading ? "none" : "block"}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VideoModal;
