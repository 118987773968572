import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  useToast,
  SimpleGrid,
  Icon,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { FiFile, FiMail, FiLinkedin, FiPhoneCall } from "react-icons/fi";
import axiosInstance from "../../../helpers/axiosInstance";

const ChartComponent = ({ selectedProgram }) => {
  const toast = useToast();
  const [insightData, setInsightData] = useState({
    api_cost: 0,
    file_count: 0,
    first_email_counter: 0,
    linkedin_connect_counter: 0,
    second_email_counter: 0,
    linkedin_counter: 0,
    cold_calling: 0,
  });

  useEffect(() => {
    const fetchInsightData = async () => {
      if (!selectedProgram) return;
      try {
        const response = await axiosInstance.post("/insight/get-insight", {
          program_name: selectedProgram.name,
        });
        // console.log("insight response", response.data);
        if (response.data.success) {
          setInsightData({
            api_cost: parseFloat(response.data.data.api_cost).toFixed(3),
            file_count: response.data.data.file_count,
            first_email_counter: response.data.data["1st_email_counter"],
            linkedin_connect_counter:
              response.data.data.linkedin_connect_counter,
            second_email_counter: response.data.data["2nd_email_counter"],
            linkedin_counter: response.data.data.linkedin_counter,
            cold_calling: response.data.data.cold_calling,
          });
        } else {
          throw new Error(
            response.data.message || "Failed to fetch insight data"
          );
        }
      } catch (error) {
        console.error("Error fetching insight data:", error);
        setInsightData({
          api_cost: 0,
          file_count: 0,
          first_email_counter: 0,
          linkedin_connect_counter: 0,
          second_email_counter: 0,
          linkedin_counter: 0,
          cold_calling: 0,
        });
      }
    };
    fetchInsightData();
  }, [selectedProgram, toast]);

  const cardData = [
    {
      label: "Uploaded Files",
      value: insightData.file_count,
      icon: FiFile,
      iconBg: "yellow.400",
      highlight: true,
    },
    {
      label: "Initial Email",
      value: insightData.first_email_counter,
      icon: FiMail,
      iconBg: "green.400",
    },
    {
      label: "LinkedIn Connect Note",
      value: insightData.linkedin_connect_counter,
      icon: FiLinkedin,
      iconBg: "blue.400",
    },
    {
      label: "Follow Up Email",
      value: insightData.second_email_counter,
      icon: FiMail,
      iconBg: "pink.400",
    },
    {
      label: "LinkedIn Message",
      value: insightData.linkedin_counter,
      icon: FiLinkedin,
      iconBg: "cyan.400",
    },
    {
      label: "Cold Calling Script",
      value: insightData.cold_calling,
      icon: FiPhoneCall,
      iconBg: "orange.400",
    },
  ];

  return (
    <Box
    height="auto"
      width="100%"
      p={{ base: 3, md: 6, lg: 8 }}
      pb={{ base: 2, md: 2, lg: 2 }}
    >
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 6 }} spacing={2}>
        {cardData.map((item, idx) => (
          <Box
            key={idx}
            bg="white"
            boxShadow={item.highlight ? "md" : "sm"}
            borderRadius="xl"
            p={4}
            transition="transform 0.2s, box-shadow 0.2s"
            _hover={{
              transform: "translateY(-2px)",
              boxShadow: "md",
            }}
          >
            {/* Card Layout: Column with label on top, value+icon on bottom row */}
            <Flex direction="column" h="100%" justifyContent="space-between">
              {/* Top: Label (left-aligned) */}
              <Tooltip
                label={item.label}
                placement="top"
                bg="white"
                color="black"
                border="1px"
                borderColor="gray.200"
                borderRadius="xl"
              >
                <Text
                  fontSize="sm"
                  color="gray.500"
                  mb={2}
                  noOfLines={1}
                  fontWeight="medium"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  cursor="default"
                >
                  {item.label}
                </Text>
              </Tooltip>

              {/* Bottom: Value on left, Icon on far right */}
              <Flex alignItems="center" justifyContent="space-between">
                <Text
                  fontSize={item.highlight ? "xl" : "lg"}
                  fontWeight="bold"
                  color="gray.800"
                  mb={0}
                >
                  {item.value ?? "0"}
                </Text>
                <Box
                  bg={item.iconBg}
                  borderRadius="md"
                  p={2}
                  color="white"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon as={item.icon} boxSize={5} />
                </Box>
              </Flex>
            </Flex>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ChartComponent;
