import React from "react";
import { Box, Image, keyframes, useBreakpointValue } from "@chakra-ui/react";
import heroBg from "../../assets/images/hero-bg.png";
import heroBgVertical from "../../assets/images/hero-bg-vertical.png";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px); /* Start from slightly below */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move to original position */
  }
`;

const HeroIllustration = () => {
  // Determine which image to display based on the screen size
  const imageSrc = useBreakpointValue({
    base: heroBgVertical, // Use vertical image for mobile screens
    md: heroBg, // Use horizontal image for tablets and larger screens
  });

  return (
    <Box as="section" position="relative" overflow="hidden" bg="white">
      <Image
        src={imageSrc}
        alt="Hero Background"
        w="100%"
        objectFit="cover"
        animation={`${fadeInUp} 1s ease-out`}
      />
    </Box>
  );
};

export default HeroIllustration;
