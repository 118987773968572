import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  Button,
  useColorModeValue,
  useToast,
  Text,
  Heading,
  HStack,
  Icon,
  Card,
  CardBody,
  Tag,
  TagLabel,
  Spacer,
  Skeleton,
} from "@chakra-ui/react";
import { updatePaymentMethod, getAutoRenewStatus, toggleAutoRenew } from "../../api/stripeApi";
import { CreditCard, RefreshCw } from "lucide-react"; // Importing an icon from lucide-react

const Billing = ({ sectionBg }) => {
  const toast = useToast();
  const [isUpdating, setIsUpdating] = useState(false);
  const [autoRenewEnabled, setAutoRenewEnabled] = useState(false);
  const [isToggling, setIsToggling] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Add useEffect to fetch initial auto-renew status
  useEffect(() => {
    fetchAutoRenewStatus();
  }, []);

  const fetchAutoRenewStatus = async () => {
    setIsLoading(true);
    try {
      const status = await getAutoRenewStatus();
      console.log("Auto-Renew status", status);
      setAutoRenewEnabled(status.auto_renew_status);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch subscription status",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleAutoRenew = async () => {
    setIsToggling(true);
    try {
      const response = await toggleAutoRenew(!autoRenewEnabled);
      // setAutoRenewEnabled(!autoRenewEnabled);
      fetchAutoRenewStatus();
      toast({
        title: "Success",
        description: autoRenewEnabled 
          ? "Auto-renewal has been disabled. Your subscription will cancel at the end of the billing period." 
          : "Auto-renewal has been enabled. Your subscription will renew automatically.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update subscription settings.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsToggling(false);
    }
  };

  const handleUpdatePaymentMethod = async () => {
    setIsUpdating(true);
    try {
      const response = await updatePaymentMethod();
      // console.log("handleUpdatePaymentMethod",response);
      if (response.data.session_url) {
        window.open(response.data.session_url, "_blank");
      } else {
        toast({
          title: "Error",
          description: "No redirect URL found. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Failed to update payment method.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box h="100vh" w="100%" overflowY="auto" bg={sectionBg}>
      <Breadcrumb spacing={2} pt={4} pl={4} pr={4} fontSize="lg" mb={0} ml="-8">
        <BreadcrumbItem>
          <BreadcrumbLink fontWeight="semibold" href="/settings/billing">
            Settings
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="/settings/billing">Billing</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider m={0} p={0} />

      <VStack p={8} align="start" spacing={8}>
        {/* Update Payment Method Section */}
        <Card w="100%" bg={useColorModeValue("white", "gray.700")} shadow="lg" borderRadius="lg">
          <CardBody>
            <HStack alignItems="center" spacing={4}>
              {/* Icon */}
              <Box p={3} bg={useColorModeValue("blue.50", "blue.900")} borderRadius="full">
                <Icon as={CreditCard} boxSize={8} color={useColorModeValue("blue.600", "blue.300")} />
              </Box>
              {/* Description */}
              <VStack align="start" spacing={1}>
                <Heading as="h3" size="md" color={useColorModeValue("gray.700", "white")}>
                  Update Payment Method
                </Heading>
                <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.300")}>
                  Securely update your payment method for seamless transactions and uninterrupted service.
                </Text>
              </VStack>
            </HStack>

            {/* Button */}
            <VStack align="end" mt={4}>
              <Button
                colorScheme="blue"
                size="md"
                borderRadius="full"
                isLoading={isUpdating}
                onClick={handleUpdatePaymentMethod}
                shadow="md"
              >
                Update Payment Method
              </Button>
            </VStack>
          </CardBody>
        </Card>

        {/* Subscription Management Card */}
        <Card w="100%" bg={useColorModeValue("white", "gray.700")} shadow="lg" borderRadius="lg">
          <CardBody>
            <HStack alignItems="center" spacing={4}>
              <Box p={3} bg={useColorModeValue("purple.50", "purple.900")} borderRadius="full">
                <Icon as={RefreshCw} boxSize={8} color={useColorModeValue("purple.600", "purple.300")} />
              </Box>
              <VStack align="start" spacing={1} flex={1}>
                <HStack w="100%">
                  <Heading as="h3" size="md" color={useColorModeValue("gray.700", "white")}>
                    Subscription Management
                  </Heading>
                  <Spacer />
                  {isLoading ? (
                    <Skeleton height="24px" width="80px" borderRadius="full" />
                  ) : (
                    <Tag
                      size="md"
                      variant="subtle"
                      colorScheme={autoRenewEnabled ? "green" : "red"}
                      borderRadius="full"
                    >
                      <TagLabel>{autoRenewEnabled ? "Active" : "InActive"}</TagLabel>
                    </Tag>
                  )}
                </HStack>
                <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.300")}>
                  {autoRenewEnabled 
                    ? "Your subscription is active and will automatically renew." 
                    : "Auto-renewal is disabled. Your subscription will cancel at the end of the billing period."}
                </Text>
              </VStack>
            </HStack>

            <HStack justify="flex-end" mt={4} spacing={4}>
              <Button
                colorScheme="red"
                size="md"
                borderRadius="full"
                isLoading={isToggling && autoRenewEnabled}
                onClick={handleToggleAutoRenew}
                shadow="md"
                isDisabled={!autoRenewEnabled}
              >
                Cancel Subscription
              </Button>
              <Button
                colorScheme="green"
                size="md"
                borderRadius="full"
                isLoading={isToggling && !autoRenewEnabled}
                onClick={handleToggleAutoRenew}
                shadow="md"
                isDisabled={autoRenewEnabled}
              >
                Activate Subscription
              </Button>
            </HStack>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
};

export default Billing;
