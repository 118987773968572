import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  List,
  ListItem,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import Footer from "./HomePage/Footer";
import Navbar from "./HomePage/Navbar";
import Logo from "../assets/images/logo-home-light.png";

const PrivacyPolicy = () => {
  const headingSize = useBreakpointValue({ base: "lg", md: "xl" });

  return (
    <Box bg="#F8F9F7" w="100%" minH="100vh" position="relative">
      <Navbar linkColor="white" buttonColor="black" buttonBgColor="white" Logo={Logo}/>
      <Box
        bg="#0F123F"
        py={10}
        px={{ base: 4, md: 40 }}
        pt={{ base: 36, md: 40, lg: 40 }}
        textAlign="center"
      >
        <Heading as="h1" size={headingSize} color="white">
          Privacy Policy
        </Heading>
        <Text color="white" mt={2} fontSize="sm">
          Effective Date: January 2025
        </Text>
      </Box>
      <VStack
        spacing={6}
        align="start"
        maxW={{ base: "90%", md: "75%", lg: "55%" }}
        mx="auto"
        py={{ base: 10, md: 12 }}
        px={4}
        bg="white"
        boxShadow="lg"
        mt={{ base: -10, md: -30 }} // Adjust this for the overlap
        borderRadius="lg"
      >
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          Smart Focus AI ("we," "us," "our") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your information when you use our SaaS platform (the
          "Platform") that generates personalized cold outreach content using
          AI.
        </Text>

        {/* Section 1: Information We Collect */}
        <Heading as="h2" size="md" color="blue.700">
          1. Information We Collect
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          <strong>a. Information You Provide to Us:</strong>
        </Text>
        <List
          spacing={2}
          pl={8}
          fontSize={{ base: "sm", md: "md" }}
          listStyleType="disc"
        >
          <ListItem>
            Account Information: Name, email address (for 2FA), and password.
          </ListItem>
          <ListItem>
            Product Information: Details you input about your products or
            services.
          </ListItem>
          <ListItem>Target Company Information: Company names & URLs.</ListItem>
          <ListItem>
            Prospect Information: First names, company names, prospect locations
            (country), and information copy-pasted from public LinkedIn
            profiles.
          </ListItem>
        </List>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          <strong>b. Automatically Collected Information:</strong>
        </Text>
        <List
          spacing={2}
          pl={8}
          fontSize={{ base: "sm", md: "md" }}
          listStyleType="disc"
        >
          <ListItem>
            Log Data: IP addresses, browser type, operating system, and
            timestamps.
          </ListItem>
          <ListItem>
            Usage Data: Information about how you interact with the Platform,
            including feature usage and preferences.
          </ListItem>
          <ListItem>
            Cookies: We use cookies to enhance your experience on the Platform.
            For more details, see our{" "}
            <Link color="blue.500" href="/cookie-policy" isExternal>
              Cookie Policy
            </Link>
            .
          </ListItem>
        </List>

        {/* Section 2: How We Use Your Information */}
        <Heading as="h2" size="md" color="blue.700">
          2. How We Use Your Information
        </Heading>
        <List
          spacing={2}
          pl={8}
          fontSize={{ base: "sm", md: "md" }}
          listStyleType="disc"
        >
          <ListItem>Provide, maintain, and improve the Platform.</ListItem>
          <ListItem>
            Generate personalized outreach content as requested.
          </ListItem>
          <ListItem>
            Authenticate users and provide secure access (e.g., 2FA).
          </ListItem>
          <ListItem>
            Communicate with you regarding account updates, support, and
            Platform changes.
          </ListItem>
          <ListItem>
            Analyze usage patterns to improve the Platform’s performance.
          </ListItem>
          <ListItem>Handle billing queries.</ListItem>
        </List>

        {/* Additional Sections */}
        <Heading as="h2" size="md" color="blue.700">
          3. Sharing Your Information
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          We do not sell or rent your personal data. However, we may share your
          information:
        </Text>
        <List
          spacing={2}
          pl={8}
          fontSize={{ base: "sm", md: "md" }}
          listStyleType="disc"
        >
          <ListItem>
            With Service Providers: Third-party providers that help us operate
            and maintain the Platform.
          </ListItem>
          <ListItem>
            For Legal Compliance: To comply with legal obligations or enforce
            our Terms of Use.
          </ListItem>
          <ListItem>
            In Case of Business Transfers: If we merge, sell, or transfer parts
            of our business, your information may be shared.
          </ListItem>
        </List>

        <Heading as="h2" size="md" color="blue.700">
          4. Data Security
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          We implement industry-standard security measures to protect your data.
          This includes encryption, secure data storage, and access controls.
          However, no security system is entirely foolproof.
        </Text>

        <Heading as="h2" size="md" color="blue.700">
          5. Data Retention
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          We retain your information as long as your account is active or as
          needed to provide the Platform. Upon request, we will delete your data
          unless retention is required by law.
        </Text>

        <Heading as="h2" size="md" color="blue.700">
          6. Your Rights
        </Heading>
        <List
          spacing={2}
          pl={8}
          fontSize={{ base: "sm", md: "md" }}
          listStyleType="disc"
        >
          <ListItem>Access, correct, or delete your data.</ListItem>
          <ListItem>Restrict or object to certain data processing.</ListItem>
          <ListItem>Request data portability.</ListItem>
          <ListItem>
            Lodge complaints with your local data protection authority.
          </ListItem>
        </List>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          To exercise your rights, contact us at{" "}
          <Link color="blue.500" href="mailto:bryan@smart-focus.ai">
            bryan@smart-focus.ai
          </Link>
          .
        </Text>

        <Heading as="h2" size="md" color="blue.700">
          7. Children’s Privacy
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          The Platform is not intended for use by individuals under the age of
          18. We do not knowingly collect personal information from children.
        </Text>

        <Heading as="h2" size="md" color="blue.700">
          8. Changes to This Privacy Policy
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          We may update this Privacy Policy from time to time. Changes will be
          posted on this page, and the "Effective Date" will be updated.
          Continued use of the Platform constitutes acceptance of the updated
          policy.
        </Text>

        <Heading as="h2" size="md" color="blue.700">
          9. Contact Us
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          If you have questions about this Privacy Policy, please contact us at:{" "}
          <Link color="blue.500" href="mailto:bryan@smart-focus.ai">
            bryan@smart-focus.ai
          </Link>
        </Text>
      </VStack>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
