import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  useBreakpointValue,
  Grid,
  GridItem,
  Link,
} from "@chakra-ui/react";
import Navbar from "./HomePage/Navbar";
import Footer from "./HomePage/Footer";
import Logo from "../assets/images/logo-home-light.png";
// import TeamImage from "../../assets/images/team.jpg"; // Replace with actual image path

const AboutUs = () => {
  const headingSize = useBreakpointValue({ base: "lg", md: "2xl" });
  const textSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <Box bg="#F8F9F7" w="100%" minH="100vh" position="relative">
      <Navbar
        linkColor="white"
        buttonColor="black"
        buttonBgColor="white"
        Logo={Logo}
      />
      <Box
        bg="#0F123F"
        py={10}
        px={{ base: 4, md: 40 }}
        pt={{ base: 36, md: 40, lg: 40 }}
        textAlign="center"
      >
        <Heading as="h1" size={headingSize} fontWeight="bold" color="white">
          About Us
        </Heading>
        <Text mt={4} fontSize={textSize} maxW="600px" mx="auto" color="white">
          Empowering businesses with innovative tools to revolutionize outreach
          strategies.
        </Text>
      </Box>

      {/* Content Section */}
      <VStack
        spacing={8}
        align="start"
        maxW={{ base: "90%", md: "75%", lg: "60%" }}
        mx="auto"
        py={16}
        px={4}
      >
        <Heading as="h2" size="lg" color="blue.700">
          Our Mission
        </Heading>
        <Text fontSize={textSize} color="gray.700">
          At Smart Focus AI, we are passionate about empowering businesses with
          innovative tools to revolutionize their outreach strategies. Our
          mission is to help sales and marketing professionals connect
          meaningfully with decision-makers through AI-driven personalized
          content.
        </Text>

        <Heading as="h2" size="lg" color="blue.700">
          Our Story
        </Heading>
        <Text fontSize={textSize} color="gray.700">
          Our story began with a challenge: Running a global sales team where
          the supporting team of exceptional Business Development
          Representatives (BDRs) excelled at securing meetings but struggled to
          gain traction with cold outreach to senior executives at $1B+ turnover
          companies.
        </Text>
        <Text fontSize={textSize} color="gray.700">
          After experimenting with several AI personalization platforms that
          fell short of expectations, our founder decided to take matters into
          their own hands. Collaborating with the co-founder, they set out to
          create a solution that would generate highly relevant and impactful
          content. By refining the variables and fine-tuning the platform, they
          built a tool that consistently delivered outstanding results.
        </Text>
        <Text fontSize={textSize} color="gray.700">
          When the platform launched, it transformed the top-of-funnel pipeline
          by enabling teams to secure meetings with high-level decision-makers.
          Today, this same innovation is at the heart of Smart Focus AI, driving
          success for teams worldwide.
        </Text>

        {/* Image Section */}
        {/* <Box w="100%" borderRadius="lg" overflow="hidden" boxShadow="md">
          <Image src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUUy7BbOMtWQ-bvrZzfixKKLCutcGyFmfCqzE68CpPLUxiKN7LksZB-fZqYW0Ixk7d4g&usqp=CAU" alt="Our Team" objectFit="cover" />
        </Box> */}

        {/* Call to Action */}
        <Box
          bg="#0F123F"
          color="white"
          w="100%"
          py={8}
          px={4}
          textAlign="center"
          borderRadius="md"
        >
          <Heading as="h3" size="lg">
            Ready to Transform Your Outreach?
          </Heading>
          <Text mt={4} fontSize={textSize} maxW="600px" mx="auto">
            Discover how Smart Focus AI can revolutionize your outreach
            strategies and help you connect meaningfully with decision-makers.
          </Text>
          <Link
            href="/signup"
            mt={6}
            display="inline-block"
            bg="#FAC900"
            color="white"
            py={2}
            px={6}
            fontWeight="bold"
            borderRadius="md"
            _hover={{ bg: "yellow.300" }}
          >
            Sign Up for Free{" "}
          </Link>
        </Box>
      </VStack>

      <Footer />
    </Box>
  );
};

export default AboutUs;
