import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  IconButton,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Button,
  Heading,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import { DeleteIcon } from "@chakra-ui/icons";
import { FaKey } from "react-icons/fa";
import { debounce } from 'lodash'; // Make sure to install lodash if not already installed

const Credentials = () => {
  const [openAiKey, setOpenAiKey] = useState("");
  const [googleSerpKey, setGoogleSerpKey] = useState("");
  const toast = useToast();

  useEffect(() => {
  
      fetchApiKeys();
    
  }, []);

  const fetchApiKeys = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/credentials/get-credential");

      if (response.data.success) {
        const { OPENAI_API, GOOGLE_SERP } = response.data.data;
        setOpenAiKey(OPENAI_API || "");
        setGoogleSerpKey(GOOGLE_SERP || "");
      } else {
        setOpenAiKey("");
        setGoogleSerpKey("");
      }
    } catch (error) {
      setOpenAiKey("");
      setGoogleSerpKey("");
      let errorMessage = "An error occurred while fetching the credentials.";
    }
  }, []);

  // Debounce the fetchApiKeys function
  const debouncedFetchApiKeys = useCallback(
    debounce(fetchApiKeys, 500),
    [fetchApiKeys]
  );

  useEffect(() => {
    debouncedFetchApiKeys();
  }, [debouncedFetchApiKeys]);

  const handleSaveKeys = async () => {
    try {
      const payload = {
        OPENAI_API: openAiKey,
        GOOGLE_SERP: googleSerpKey,
      };

      const response = await axiosInstance.post("/credentials/keys", payload);
      
      if (response.data.success) {
        toast({
          title: "Success",
          description: response.data.message || "API keys have been saved successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        throw new Error(response.data.message || "Failed to save API keys");
      }
    } catch (error) {
      let errorMessage = "An error occurred while saving the credentials.";

      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      console.error("Error saving credentials:", error);
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleDeleteKeys = async () => {
    try {
      await axiosInstance.delete("/credentials/delete-credentials");
      setOpenAiKey("");
      setGoogleSerpKey("");
      toast({
        title: "API Keys Deleted",
        description: "API keys have been deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      let errorMessage = "An error occurred while deleting the credentials.";

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      console.error("Error deleting credentials:", error);
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Box w="50%">
      {/* <Heading size="md" mb={6}>
        API Credentials
      </Heading> */}
      <FormControl mb={4} w="100%">
        <FormLabel fontSize="md" fontWeight="bold">
          OpenAI Key
        </FormLabel>
        <Input
          value={openAiKey}
          onChange={(e) => setOpenAiKey(e.target.value)}
          placeholder="Enter OpenAI Key"
          fontSize="sm"
          borderRadius="xl"
        />
      </FormControl>
      <FormControl mb={4} w="100%">
        <FormLabel fontSize="md" fontWeight="bold">
          Google SERPER Key
        </FormLabel>
        <Input
          value={googleSerpKey}
          onChange={(e) => setGoogleSerpKey(e.target.value)}
          placeholder="Enter Google SERPER Key"
          fontSize="sm"
          borderRadius="xl"
        />
      </FormControl>
      <Box mt={4}>
        <Button
         onClick={handleDeleteKeys}
          colorScheme="red"
          borderRadius="full"
          size="sm"
          mr={3}
        >
          Delete
        </Button>
        <Button
          onClick={handleSaveKeys}
          colorScheme="blue"
          borderRadius="full"
          size="sm"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default Credentials;
