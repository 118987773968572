import React from "react";
import { useHistory } from "react-router-dom";
import {
  Flex,
  HStack,
  Image,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../auth";
import { useGoogleAuth } from "../common/google/GoogleAuthContext";

const Navbar = ({
  linkColor = "black",
  buttonColor = "white",
  buttonBgColor = "#08072B",
  Logo,
}) => {
  const [logged] = useAuth();
  const { authState } = useGoogleAuth();
  const breakpoint = useBreakpointValue({
    base: "mobile",
    md: "tablet",
    lg: "desktop",
  });
  const isUserLoggedIn = logged || authState.isLoggedIn;
  const history = useHistory();

  // Handle function for redirecting to the login page
  const handleSignIn = () => {
    if (isUserLoggedIn) {
      // If already logged in, go directly to /program
      history.push("/program");
    } else {
      // Otherwise go to /login
      history.push("/login");
    }
  };

  if (breakpoint === "mobile") {
    return (
      <Flex
        as="header"
        align="center"
        justify="space-between"
        px={4}
        bg="transparent"
        position="absolute"
        w="100%"
        top={0}
        zIndex={10}
        mt={4}
      >
        <Link as={RouterLink} to="/home">
          <Image src={Logo} alt="Logo" height="50px" width="auto" />
        </Link>
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HamburgerIcon />}
            variant="outline"
            aria-label="Options"
            color={linkColor}
          />
          <MenuList>
            <MenuItem as={Link} href="/home">
              Home
            </MenuItem>
            <MenuItem as={Link} href="/pricing">
              Pricing
            </MenuItem>
            <MenuItem as={Link} href="/about-us">
              About us
            </MenuItem>
            <MenuItem onClick={handleSignIn}>Sign In</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    );
  }

  if (breakpoint === "tablet") {
    return (
      <Flex
        as="header"
        align="center"
        justify="space-between"
        px={8}
        bg="transparent"
        position="absolute"
        w="100%"
        top={0}
        zIndex={10}
        mt={4}
      >
        <Link as={RouterLink} to="/home">
          <Image src={Logo} alt="Logo" height="70px" width="auto" />
        </Link>
        <HStack>
          <Button variant="unstyled" fontWeight="normal" colorScheme="white" onClick={handleSignIn}>
            Sign In
          </Button>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<HamburgerIcon />}
              variant="outline"
              aria-label="Options"
              color={linkColor}
            />
            <MenuList>
              <MenuItem as={Link} href="/home">
                Home
              </MenuItem>
              <MenuItem as={Link} href="/pricing">
                Pricing
              </MenuItem>
              <MenuItem as={Link} href="/about-us">
                About us
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    );
  }

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      px={16}
      bg="transparent"
      position="absolute"
      w="100%"
      top={0}
      zIndex={10}
      mt={4}
    >
      <Link as={RouterLink} to="/home">
        <Image src={Logo} alt="Logo" height="80px" width="auto" />
      </Link>
      <HStack spacing={6}>
        <Link href="/home" fontWeight="normal" color={linkColor} mx={4}>
          Home
        </Link>
        <Link href="/pricing" fontWeight="normal" color={linkColor} mx={4}>
          Pricing
        </Link>
        <Link href="/about-us"  fontWeight="normal" color={linkColor} mx={4}>
          About us
        </Link>
      </HStack>

      <HStack spacing={4}>
        <Button variant="unstyled" fontWeight="normal" color={linkColor} onClick={handleSignIn}>
          Sign In
        </Button>
        <Button
          bg={buttonBgColor}
          color={buttonColor}
          _hover="none"
          as={Link}
          href="/signup"
        >
          Sign Up for Free
        </Button>
      </HStack>
    </Flex>
  );
};

export default Navbar;
