import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Button,
  PinInput,
  PinInputField,
  HStack,
  Link,
  useToast,
  Image,
  Divider,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../assets/images/logo-home-dark.png";
import DotsSpinner from "./DotsSpinner";

const SignupVerificationPage = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false); // NEW: controls spinner display
  const [timer, setTimer] = useState(120); // 2-minute countdown in seconds
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  // Retrieve the email from signup
  const email = location.state?.email || "";

  // If no email, redirect to signup
  useEffect(() => {
    if (!email) {
      history.push("/signup");
    }

    // Start the countdown timer
    const interval = setInterval(() => {
      setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, [email, history]);

  // Format the timer for mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Handle verifying the signup 2FA
  const verifySignup2FA = async () => {
    setIsVerifying(true);

    // 1) Start a 5-second timer to show spinner if the backend is slow
    const spinnerTimeout = setTimeout(() => {
      setShowSpinner(true);
    }, 5000);

    try {
      const response = await fetch("/auth/verify-signup-2fa", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, code: verificationCode }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        toast({
          title: "Signup Complete",
          description: data.message || "Account verified successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // If there's a payment link, open in new tab; otherwise go to login
        if (data.data?.session_url) {
          window.open(data.data.session_url, "_blank");
        } else {
          history.push("/login");
        }
      } else {
        toast({
          title: "Verification Failed",
          description: data.message || "Your code is invalid or expired.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Network Error",
        description: "Unable to connect to the server.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      // 2) Clear the spinner timer, so we don't keep it running
      clearTimeout(spinnerTimeout);
      // 3) Hide spinner again (in case it appeared)
      setShowSpinner(false);
      setIsVerifying(false);
    }
  };

  // --- RENDER ---

  // If the spinner is active, show the "Please wait..." UI
  if (showSpinner) {
    return (
      <Flex minH="100vh" align="center" justify="center" bg="#F7F7F7">
        <Stack align="center" spacing={4}>
          <Heading size="md" mb={0}>
            Give us 60 seconds while your product profile is being generated...
          </Heading>
          <Text
            fontSize="md"
            fontWeight="medium"
            color="gray.600"
            textAlign="center"
            w="50%"
            mb={0}
          >
            We are building a tailored product profile for you, incorporating
            your company name and domain. This will streamline the process of
            generating highly personalized emails with ease.
          </Text>

          <DotsSpinner />
        </Stack>
      </Flex>
    );
  }

  // Otherwise, show the normal 2FA UI
  return (
    <Flex minH="100vh" align="center" justify="center" bg="#F7F7F7">
      <Box
        w="full"
        maxW="md"
        bg="white"
        p={{ base: 6, md: 8 }}
        boxShadow="lg"
        borderRadius="lg"
      >
        {/* Optional logo */}
        <Flex justify="center" mb={4}>
          <Image src={logo} alt="Logo" width="200px" h="auto" />
        </Flex>

        <Stack spacing={4} textAlign="center">
          <Heading size="md" color="#3A4135" fontWeight="medium">
            Verify Your Signup
          </Heading>
          <Text color="gray.600" fontSize="sm">
            We've sent a 2FA code to:
            <br />
            <Text as="span" fontWeight="bold">
              {email}
            </Text>
            <br />
            Please enter the code below to complete your account creation.
          </Text>

          {/* Pin Input for 6-digit code */}
          <HStack justify="center">
            <PinInput
              otp
              size="lg"
              onChange={(value) => setVerificationCode(value)}
              placeholder=""
            >
              {Array(6)
                .fill("")
                .map((_, i) => (
                  <PinInputField key={i} />
                ))}
            </PinInput>
          </HStack>

          {/* Timer */}
          <Text fontSize="sm" color={timer > 0 ? "black" : "red"}>
            Time remaining: {formatTime(timer)}
          </Text>

          {timer === 0 && (
            <Text fontSize="sm" color="red">
              Your code has expired. Please{" "}
              <Link href="/signup" color="blue.600" fontWeight="medium">
                sign up
              </Link>{" "}
              again to request a new code.
            </Text>
          )}

          <Button
            colorScheme="blue"
            onClick={verifySignup2FA}
            isLoading={isVerifying}
            isDisabled={timer === 0 || verificationCode.length < 6}
          >
            Submit
          </Button>
        </Stack>

        {/* Footer (Optional) */}
        <Box pt={10}>
          <HStack spacing={2} align="center" justify="center">
            <Link
              fontSize="sm"
              href="/terms-of-service"
              fontWeight="medium"
              color="blue.600"
            >
              Terms of Use
            </Link>
            <Divider
              orientation="vertical"
              height="12px"
              borderColor="blackAlpha.400"
              borderWidth="0.5px"
            />
            <Link
              fontSize="sm"
              href="/privacy-policy"
              fontWeight="medium"
              color="blue.600"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default SignupVerificationPage;
