import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
  Icon,
  HStack,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const PlanCard = ({
  title,
  price,
  period,
  credits,
  description,
  features,
  bg,
  buttonbg,
  color,
  icon,
  onClick,
  isDisabled,
  buttonText,
  seatIncrement,
  seatDecrement,
  seatCount,
  plan,
  style,
  isAnnual,
  isLoading,
  symbol,
  TotalteamsAnnualPricePerSeat,
  TotalindividualAnnualPrice,
  teamsMonthlyPrice,
}) => {
  // 1) Track whether the modal is open
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 2) Event handler when user clicks the main button
  const handleButtonClick = () => {
    // Open modal for "Teams" and "Individual" plans (both Monthly & Annual)
    if (title === "Teams" || title === "Individual") {
      setIsModalOpen(true);
    } else {
      // If it's the Free plan, trigger the regular onClick action
      if (onClick) onClick();
    }
  };

  // 3) Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box textAlign="left" style={style}>
      <VStack align="flex-start" spacing={3}>
        <Box
          bg={bg}
          border="1px solid"
          borderColor="blackAlpha.300"
          boxShadow="lg"
          rounded="xl"
          p={6}
        >
          <HStack justifyContent="space-between" spacing={1}>
            <Icon as={icon} boxSize={8} color="green.400" mb={4} />
            {title === "Teams" && (
              <Text color="gray.500" mb={0} mr={7}>
                (Min 5 seats)
              </Text>
            )}
          </HStack>

          <HStack alignContent="bottom" justifyContent="space-between">
            <HStack>
              <Heading size="md" fontWeight="bold" mb={0}>
                {title}
              </Heading>
              {title === "Free" && (
                <Text fontSize="sm" color="gray.500" mb={0}>
                  (No Credit Card Required)
                </Text>
              )}
            </HStack>
            {title === "Teams" && (
              <HStack justifyContent="center" spacing={1} h="24px">
                <Button onClick={seatDecrement} size="sm" bg="none">
                  -
                </Button>
                <Input
                  value={seatCount}
                  readOnly
                  textAlign="center"
                  size="xs"
                  borderRadius="lg"
                />
                <Button onClick={seatIncrement} size="sm" bg="none">
                  +
                </Button>
              </HStack>
            )}
          </HStack>

          {/* Pricing Section */}
          <Box display="flex" alignItems="baseline">
            <Text fontSize="4xl" fontWeight="bold" mr={1} mb={0}>
              {price}
            </Text>

            {/* Show strike-through only for annual plans */}
            {isAnnual && plan.originalPrice && (
              <Text
                fontSize="sm"
                color="gray.500"
                textDecoration="line-through"
                mr={2}
                mb={0}
              >
                {plan.originalPrice}
              </Text>
            )}

            <Text color="gray.500" mb={0}>
              / {period}
            </Text>
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <Text fontSize="1xl" fontWeight="bold" mr={1} mb={0}>
              {credits}
            </Text>
            <Text fontSize="sm" color="gray.500" mb={0}>
              credits / seat
            </Text>
            {title === "Teams" && (
              <Box
                ml={2}
                px={2}
                py={0.5}
                bg="green.100"
                color="green.800"
                fontSize="xs"
                fontWeight="bold"
                rounded="md"
              >
                +33% more!
              </Box>
            )}
          </Box>

          <Text color="gray.600">{description}</Text>

          <Button
            mt={4}
            bg={buttonbg}
            color={color}
            _hover="none"
            _active="none"
            borderColor="#08072B"
            variant="outline"
            rounded="full"
            w="full"
            isDisabled={isDisabled}
            onClick={handleButtonClick}
            isLoading={isLoading}
          >
            {buttonText}
          </Button>
        </Box>

        {/* Features List */}
        <Box mt={4}>
          <Text fontWeight="semibold" mb={2}>
            What's included
          </Text>
          <List spacing={2} pl={0}>
            {features.map((feature, index) => (
              <ListItem key={index}>
                <ListIcon as={CheckCircleIcon} color="#08072B" />
                {feature}
              </ListItem>
            ))}
          </List>
        </Box>
      </VStack>

      {/* 4) The Modal (Only relevant when isAnnual = true) */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title} Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Example Container */}
            <Box
              borderWidth="1px"
              borderRadius="lg"
              p={6}
              bg="white"
              mb={4}
              boxShadow="md"
            >
              <VStack spacing={3} align="stretch">
                {/* Monthly Price */}
                <HStack justifyContent="space-between">
                  <Text fontSize="md" color="gray.600" mb={0}>
                    Price per month:
                  </Text>
                  <Text fontSize="lg" fontWeight="bold" color="blue.600" mb={0}>
                    {price}{" "}
                    {isAnnual && plan.originalPrice && (
                    <Text
                      as="span"
                      fontSize="sm"
                      color="gray.500"
                      textDecoration="line-through"
                      mr={2}
                      mb={0}
                    >
                      {plan.originalPrice}
                    </Text>
                    )}
                    <Text as="span" fontSize="sm" color="gray.500" mb={0}>
                      {title === "Teams" ? "/ seat / month" : "/ month"}{" "}
                    </Text>
                  </Text>
                </HStack>

                {title === "Teams" && (
                  <HStack justifyContent="space-between" w="100%">
                    <Text fontSize="md" color="gray.600" mb={0}>
                      Seats:
                    </Text>
                    <HStack justifyContent="flex-end">
                      <Button onClick={seatDecrement} size="sm" bg="none">
                        -
                      </Button>
                      <Input
                        value={seatCount}
                        readOnly
                        textAlign="center"
                        size="xs"
                        w="25%"
                        borderRadius="lg"
                      />
                      <Button onClick={seatIncrement} size="sm" bg="none">
                        +
                      </Button>
                    </HStack>
                  </HStack>
                )}

                {!(title === "Individual" && !isAnnual) && (
                  <Box borderBottomWidth="1px" borderColor="gray.300" />
                )}

                {isAnnual && (
                  <HStack justifyContent="space-between" mt={2}>
                    <Text fontSize="md" color="gray.600" mb={0}>
                      {title === "Teams"
                        ? " Total for 12 months (Min 5 seats):"
                        : " Total for 12 months:"}
                    </Text>
                    <Text
                      fontSize="lg"
                      fontWeight="bold"
                      color="blackAlpha.800"
                      mb={0}
                    >
                      {symbol}
                      {title === "Teams"
                        ? TotalteamsAnnualPricePerSeat
                        : TotalindividualAnnualPrice}{" "}
                      <Text as="span" fontSize="sm" color="gray.500" mb={0}>
                        / year
                      </Text>
                    </Text>
                  </HStack>
                )}

                {!isAnnual && title === "Teams" && (
                  <HStack justifyContent="space-between" mt={2}>
                    <Text fontSize="md" color="gray.600" mb={0}>
                      Total for {seatCount} seats (Min 5 seats):
                    </Text>
                    <Text
                      fontSize="lg"
                      fontWeight="bold"
                      color="blackAlpha.800"
                      mb={0}
                    >
                      {symbol}
                      {teamsMonthlyPrice}{" "}
                      <Text as="span" fontSize="sm" color="gray.500" mb={0}>
                        / month
                      </Text>
                    </Text>
                  </HStack>
                )}

                {/* Savings Section */}
                {isAnnual && (
                  <Box
                    p={3}
                    bg="green.50"
                    borderRadius="md"
                    textAlign="center"
                    mt={2}
                  >
                    <Text fontSize="md" fontWeight="bold" color="green.800" mb={0}>
                      🎉 You Save: <Text as="span" mb={0}>Up-to 20%</Text>
                    </Text>
                  </Box>
                )}
              </VStack>
            </Box>

            {/* Additional marketing or explanatory text */}
            {isAnnual && (
              <Text fontSize="sm" color="gray.600" mb={0}>
                By choosing the annual plan, you enjoy a lower rate per month
                and lock in significant savings over a 12-month period.
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            {/* "Get Plan" Button in the Modal */}
            <Button
              bg={buttonbg}
              color={color}
              _hover="none"
              _active="none"
              borderColor="#08072B"
              variant="outline"
              rounded="full"
              // w="full"
              onClick={() => {
                // Your subscription logic for the annual plan
                // e.g., call an API, redirect, etc.
                onClick?.();
                handleCloseModal();
              }}
            >
              {buttonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PlanCard;
