// usePersistedStateCustom.js

import { useState } from "react";

function removeFileLists(item) {
  // If it's an array, map through each element and remove file_list from each
  if (Array.isArray(item)) {
    return item.map((element) => removeFileLists(element));
  }
  // If it's a non-null object, check for file_list and also sanitize its fields
  else if (item && typeof item === "object") {
    const { file_list, ...rest } = item; // remove file_list if it exists
    // Also recursively remove file_list inside nested objects
    for (const key in rest) {
      rest[key] = removeFileLists(rest[key]);
    }
    return rest;
  }
  // Otherwise (string, number, null, etc.) just return as is
  return item;
}

function usePersistedStateCustom(key, initialValue) {
  const [state, setState] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error("Failed to parse item from localStorage:", error);
      return initialValue;
    }
  });

  const setPersistedState = (value) => {
    setState((prevState) => {
      const valueToStore = typeof value === "function" ? value(prevState) : value;
      try {
        // Deep remove all file_lists
        const sanitized = removeFileLists(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(sanitized));
        return valueToStore; // State still holds the *full* object or array
      } catch (error) {
        console.error("Failed to set item to localStorage:", error);
        return valueToStore;
      }
    });
  };

  return [state, setPersistedState];
}

export default usePersistedStateCustom;


// import { useState } from "react";

// function usePersistedStateCustom(key, initialValue) {
//   const [state, setState] = useState(() => {
//     // On first render, read from localStorage
//     try {
//       const item = window.localStorage.getItem(key);
//       return item ? JSON.parse(item) : initialValue;
//     } catch (error) {
//       console.error("Failed to parse item from localStorage:", error);
//       return initialValue;
//     }
//   });

//   const setPersistedState = (value) => {
//     setState((prevState) => {
//       const valueToStore = typeof value === "function" ? value(prevState) : value;

//       try {
//         // If it's an object and has file_list, remove it before storing
//         if (valueToStore && typeof valueToStore === "object" && "file_list" in valueToStore) {
//           const { file_list, ...rest } = valueToStore;
//           window.localStorage.setItem(key, JSON.stringify(rest));
//         } else {
//           window.localStorage.setItem(key, JSON.stringify(valueToStore));
//         }
//       } catch (error) {
//         console.error("Failed to set item to localStorage:", error);
//       }

//       return valueToStore; // React state still holds the full object
//     });
//   };

//   return [state, setPersistedState];
// }

// export default usePersistedStateCustom;
