import React from "react";
import { Box, Heading, Text, VStack, useColorModeValue } from "@chakra-ui/react";

const CardInfoNotUpdated = () => {
  const sectionBg = useColorModeValue("white", "gray.800");

  return (
    <Box h="100vh" w="100%" bg={sectionBg} display="flex" justifyContent="center" alignItems="center">
      <VStack spacing={4} align="center">
        <Heading size="lg" color="red.500">
          Update Canceled
        </Heading>
        <Text>Your payment method update was canceled or failed. Please try again.</Text>
      </VStack>
    </Box>
  );
};

export default CardInfoNotUpdated;
