import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance";
import { useHistory } from "react-router-dom";

function SenderProfileDropdown({
  selectedValue,
  onChange = () => {},
  programId,
  fileUniqueId,
}) {
  const [profiles, setProfiles] = useState([]);
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    fetchSenderProfile();
  }, []);

  const fetchSenderProfile = async () => {
    try {
      const response = await axiosInstance.get("/sender_profile/");
      if (response.status === 200 && response.data.success) {
        setProfiles(response.data.data.profiles || []);
      }
    } catch (error) {
      console.error("Error fetching profiles:", error);
    }
  };

  const handleChange = async (value) => {
    const selectedProfile = profiles.find((profile) => profile._id === value);
    if (!selectedProfile) return;

    const payload = {
      sender_profile_id: selectedProfile._id,
      sender_name: selectedProfile.sender_name,
    };

    try {
      const response = await axiosInstance.post(
        `/program/set-sender-profile/${programId}/${fileUniqueId}`,
        payload
      );
      if (response.status === 200 && response.data.success) {
        toast({
          title: "Sender Profile Updated",
          description: response.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
      onChange(value); // Call onChange to trigger fetchSelectedProgram
    } catch (error) {
      console.error("Error updating sender profile:", error);
      toast({
        title: "Error",
        description: "Failed to update the sender profile.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        bg="none"
        border="1px solid"
        borderRadius="full"
        borderColor="gray.200"
        _active="none"
        _focus="none"
        _hover="none"
        size="sm"
        rightIcon={<ChevronDownIcon />}
      >
        {selectedValue
          ? profiles.find((profile) => profile._id === selectedValue)
              ?.sender_name || "Select"
          : "Select"}
      </MenuButton>
      <MenuList>
        {profiles.map((profile) => (
          <MenuItem key={profile._id} onClick={() => handleChange(profile._id)}>
            {profile.sender_name}
          </MenuItem>
        ))}
        <MenuItem onClick={() => history.push("/sender_information")}>
          <AddIcon boxSize={3} mr={2} /> Add
        </MenuItem>
      </MenuList>
    </Menu>
  );
}

export default SenderProfileDropdown;
