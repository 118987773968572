import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import gradientLeft from "../../assets/svg/gradient-left.svg";
import gradientRight from "../../assets/svg/gradient-right.svg";
import HeroIllustration from "./HeroIllustration";
import OpsUISection from "./OpsUISection";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Logo from "../../assets/images/logo-home-dark.png";

const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButton = motion(Button);
const MotionBox = motion(Box);
const HomePage = () => {
  const showSvg = useBreakpointValue({ base: false, md: false, lg: true });

  return (
    <Box bg="white" w="100%" minH="100vh" position="relative" overflow="hidden">
      <Navbar Logo={Logo}/>

      <Box
        as="section"
        position="relative"
        textAlign="center"
        alignContent="center"
        zIndex={5}
        pl={{ base: 4, md: 12 }}
        pr={{ base: 4, md: 12 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <VStack
          spacing={4}
          pt={{ base: 36, md: 40, lg: 48 }}
          pb={{ base: 8, md: 10 }}
          width={{ base: "100%", md: "100%", lg: "65%" }}
        >
          <MotionHeading
            as="h1"
            color="#08072B"
            fontSize={{ base: "3xl", md: "52px", lg: "6xl" }}
            fontWeight="medium"
            initial={{ opacity: 0, y: 20 }} // Bottom-to-top transition
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Supercharge your outreach with AI-Driven personalization
          </MotionHeading>
          <MotionText
            fontSize={{ base: "md", md: "lg" }}
            color="#6C6B7C"
            initial={{ opacity: 0, y: 20 }} // Bottom-to-top transition
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
          >
            Generate highly personalized emails, LinkedIn messages, and cold
            calling scripts in seconds.
          </MotionText>

          <MotionButton
            size={{ base: "lg", md: "lg" }}
            bg="#08072B"
            color="white"
            as="a"
            href="/signup"
            initial={{ opacity: 0, y: 20 }} // Bottom-to-top transition
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.6 }}
            _hover={{ bg: "#050523" }}
          >
            Sign up for Free
          </MotionButton>
          <MotionText
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="bold"
            color="#08072B"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3 }}
            mb={0}
          >
            💳❌ No Credit Card Required
          </MotionText>
        </VStack>
      </Box>

      {showSvg && (
        <>
          <Image
            src={gradientLeft}
            alt="Left Gradient"
            position="absolute"
            top="0"
            left="0"
            w="350px"
            zIndex={0}
          />
          <Image
            src={gradientRight}
            alt="Right Gradient"
            position="absolute"
            top="0"
            right="0"
            w="600px"
            zIndex={0}
          />
        </>
      )}

      {/* <HeroIllustration /> */}
      <OpsUISection />

      <Footer />
    </Box>
  );
};

export default HomePage;
