// BillingToggle.js
import React from "react";
import { Box, Button, Flex, HStack } from "@chakra-ui/react";

const BillingToggle = ({ isAnnual, setIsAnnual, bg, activeBg }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      mb={10}
      direction="column"
    >
      {/* Segmented control container */}
      <Box
        display="inline-flex"
        alignItems="center"
        borderRadius="xl"
        bg={bg}
        p="1"
        // px={4}
      >
        {/* Monthly Button */}
        <Button
          size="sm"
          variant="unstyled"
          borderRadius="xl"
          px={{ base: 4, md: 10, lg: 10 }}
          py={2}
          fontWeight="bold"
          bg={!isAnnual ? activeBg : "transparent"}
          color={!isAnnual ? "blue.600" : "gray.500"}
          onClick={() => setIsAnnual(false)}
        >
          Monthly
        </Button>
        <HStack
          bg={isAnnual ? activeBg : "transparent"}
          borderRadius="xl"
          spacing={0}
        >
          {/* Annually Button */}
          <Button
            size="sm"
            variant="unstyled"
            px={{ base: 4, md: 10, lg: 10 }}
            py={2}
            pr={
              !isAnnual
                ? { base: 4, md: 10, lg: 10 }
                : { base: 2, md: 2, lg: 2 }
            }
            fontWeight="bold"
            color={isAnnual ? "blue.600" : "gray.500"}
            onClick={() => setIsAnnual(true)}
          >
            Annually
          </Button>
          {/* Badge shown if Annual is selected */}
          {isAnnual && (
            <Box
              mr={1}
              bg="green.100"
              color="green.800"
              fontSize="10px"
              size="sm"
              fontWeight="medium"
              px={2}
              py={1}
              borderRadius="xl"
            >
              Save Up-to 20%
            </Box>
          )}
        </HStack>
      </Box>
    </Flex>
  );
};

export default BillingToggle;
