import React from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import Navbar from "./HomePage/Navbar";
import Footer from "./HomePage/Footer";
import Logo from "../assets/images/logo-home-light.png";

const TermsOfUse = () => {
  const headingSize = useBreakpointValue({ base: "lg", md: "xl" });

  return (
    <Box bg="#F8F9F7" w="100%" minH="100vh" position="relative">
      <Navbar linkColor="white" buttonColor="black" buttonBgColor="white" Logo={Logo}/>
      <Box
        bg="#0F123F"
        py={10}
        px={{ base: 4, md: 40 }}
        pt={{ base: 36, md: 40, lg: 40 }}
        textAlign="center"
      >
        <Heading as="h1" size={headingSize} color="white">
          Terms of Service
        </Heading>
        <Text color="white" mt={2} fontSize="sm">
          Updated January 2025
        </Text>
      </Box>
      <VStack
        spacing={6}
        align="start"
        maxW={{ base: "90%", md: "75%", lg: "55%" }}
        mx="auto"
        py={{ base: 10, md: 12 }}
        px={4}
        bg="white"
        boxShadow="lg"
        mt={{ base: -10, md: -30 }} // Adjust this for the overlap
        borderRadius="lg"
      >
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          These Terms of Use ("Terms") govern your use of the SaaS platform (the
          "Platform") provided by Smart Focus AI ("we," "us," "our"). By
          accessing or using the Platform, you agree to these Terms. If you do
          not agree, do not use the Platform.
        </Text>

        {/* Section 1: Use of the Platform */}
        <Heading as="h2" size="md" color="blue.700">
          1. Use of the Platform
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          <strong>a. Eligibility:</strong> You must be at least 18 years old to
          use the Platform.
        </Text>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          <strong>b. Account Security:</strong> You are responsible for
          maintaining the confidentiality of your account credentials and for
          all activity under your account. Use of two-factor authentication
          (2FA) is mandatory.
        </Text>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          <strong>c. Permitted Use:</strong> You may use the Platform solely for
          lawful purposes to generate personalized outreach content for business
          purposes. You agree not to:
        </Text>

        <List
          spacing={2}
          pl={8}
          fontSize={{ base: "sm", md: "md" }}
          listStyleType="disc"
        >
          <ListItem>
            Use the Platform to violate any laws or regulations.
          </ListItem>
          <ListItem>Provide false or misleading information.</ListItem>
          <ListItem>
            Reverse-engineer, copy, or distribute the Platform’s technology.
          </ListItem>
        </List>

        {/* Section 2: User Responsibilities */}
        <Heading as="h2" size="md" color="blue.700">
          2. User Responsibilities
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          <strong>a. Input Data:</strong> You are solely responsible for
          ensuring that the data you input (e.g., prospect details, company
          names, LinkedIn profile information) complies with applicable laws,
          including data privacy and intellectual property laws.
        </Text>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          <strong>b. Generated Content:</strong> You acknowledge that the
          content generated by the Platform is for informational purposes only
          and should be reviewed before use. We are not responsible for any
          outcomes arising from your use of the generated content.
        </Text>

        {/* Additional Sections */}
        <Heading as="h2" size="md" color="blue.700">
          3. Intellectual Property
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          The Platform and all related intellectual property, including
          algorithms, software, and designs, are owned by us. You are granted a
          limited, non-exclusive, non-transferable license to use the Platform
          in accordance with these Terms.
        </Text>

        <Heading as="h2" size="md" color="blue.700">
          4. Privacy
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          Our collection and use of your information are governed by our{" "}
          <Link color="blue.500" href="/privacy-policy" isExternal>
            Privacy Policy
          </Link>
          . By using the Platform, you consent to these practices.
        </Text>

        <Heading as="h2" size="md" color="blue.700">
          5. Fees and Payment
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          <strong>a. Subscription Fees:</strong> Access to the Platform is free
          for limited use and requires payment for applicable subscriptions.
          Where subscription requires payment, fees are non-refundable unless
          otherwise stated.
        </Text>

        {/* Add more sections as needed */}
        <Heading as="h2" size="md" color="blue.700">
          Contact Us
        </Heading>
        <Text fontSize={{ base: "sm", md: "md" }} color="gray.700">
          If you have questions about these Terms, please contact us at:{" "}
          <Link color="blue.500" href="mailto:bryan@smart-focus.ai">
            bryan@smart-focus.ai
          </Link>
        </Text>
      </VStack>
      <Footer />
    </Box>
  );
};

export default TermsOfUse;
