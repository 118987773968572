import React, { useState, useEffect } from "react";
import {
  Box,
  Tooltip,
  Icon,
  Button,
  Text,
  Input,
  VStack,
  Flex,
  Heading,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  useToast,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorMode,
  Spacer,
} from "@chakra-ui/react";
import { SettingsIcon, DeleteIcon } from "@chakra-ui/icons";
import OrganizationSettings from "./OrganizationSettings";
import axiosInstance from "../../helpers/axiosInstance";
import { useHistory } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import UserProfileMenu from "../UserProfileMenu";
import Pagination from "../Program/MiddlePannel/Pagination";

function AdminPanel() {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const toast = useToast();

  // -----------------------------
  // Organization Data
  // -----------------------------
  const [organizations, setOrganizations] = useState([]);

  // We store the ID and Name separately
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [selectedOrganizationName, setSelectedOrganizationName] = useState("");

  // -----------------------------
  // Users & Pagination
  // -----------------------------
  const [users, setUsers] = useState([]);
  const roles = ["Admin", "Manager", "Employee"];
  const [currentPage, setCurrentPage] = useState(1);
  const [fileCount, setFileCount] = useState(7);
  const [totalPages, setTotalPages] = useState(1);

  // -----------------------------
  // Modals & Alerts
  // -----------------------------
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // For OrganizationSettings
  const [isGeneratingContent, setIsGeneratingContent] = useState(false);
  const [isRoleChangeAlertOpen, setIsRoleChangeAlertOpen] = useState(false);
  const [isDeleteUserAlertOpen, setDeleteUserAlertOpen] = useState(false);

  // -----------------------------
  // Form & Errors
  // -----------------------------
  const [newUserData, setNewUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
  });
  const [errors, setErrors] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [userToChange, setUserToChange] = useState({ email: "", role: "" });
  const [emailToDelete, setEmailToDelete] = useState("");

  // -----------------------------
  // Organization Accordion
  // -----------------------------
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  // **IMPORTANT**: Only display `company_name`, but store the ID
  const handleSetOrganization = (orgId, orgName) => {
    setSelectedOrganizationId(orgId);
    setSelectedOrganizationName(orgName);
    localStorage.setItem("selectedOrganizationId", orgId);
    localStorage.setItem("selectedOrganizationName", orgName);
    setCurrentPage(1);
  };

  // -----------------------------
  // Fetch Organizations
  // -----------------------------
  const fetchOrganizations = () => {
    axiosInstance.get("/org/get_organizations").then((response) => {
      // Example: response.data.data.organizations = [{ organization: "123", company_name: "ACME" }, ...]
      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data.organizations)
      ) {
        setOrganizations(response.data.data.organizations);
      } else {
        console.error(
          "API did not return an array in 'organizations' key:",
          response.data
        );
        setOrganizations([]);
      }
    });
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  // -----------------------------
  // Local Storage: Restore org
  // -----------------------------
  useEffect(() => {
    const savedOrgId = localStorage.getItem("selectedOrganizationId");
    const savedOrgName = localStorage.getItem("selectedOrganizationName");
    if (savedOrgId) {
      setSelectedOrganizationId(savedOrgId);
    }
    if (savedOrgName) {
      setSelectedOrganizationName(savedOrgName);
    }
  }, []);

  // -----------------------------
  // Fetch Users
  // -----------------------------
  const fetchUsers = (orgId, page, limit) => {
    if (!orgId) return;
    axiosInstance
      .get(
        `/auth/admin/get_users?page=${page}&limit=${limit}&organization=${orgId}`
      )
      .then((response) => {
        if (response.data && response.data.data) {
          setUsers(response.data.data);
          if (response.data.meta) {
            setTotalPages(response.data.meta.total_pages);
          }
        } else {
          console.error("Unexpected API response format");
          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  // Refresh user list when the selected organization or pagination changes
  useEffect(() => {
    if (selectedOrganizationId) {
      fetchUsers(selectedOrganizationId, currentPage, fileCount);
    }
  }, [selectedOrganizationId, currentPage, fileCount]);

  // -----------------------------
  // Pagination
  // -----------------------------
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchUsers(selectedOrganizationId, nextPage, fileCount);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const prevPageNumber = currentPage - 1;
      setCurrentPage(prevPageNumber);
      fetchUsers(selectedOrganizationId, prevPageNumber, fileCount);
    }
  };

  // -----------------------------
  // Add User
  // -----------------------------
  const validate = (data) => {
    const errors = {};
    if (!data.first_name) errors.first_name = "First Name is required";
    if (!data.last_name) errors.last_name = "Last Name is required";
    if (!data.email) errors.email = "Email is required";
    if (!data.password) errors.password = "Password is required";
    if (!data.role) errors.role = "Role is required";
    return errors;
  };

  const addUser = async () => {
    setIsGeneratingContent(true);

    try {
      const response = await axiosInstance.post("/auth/signup", {
        ...newUserData,
        // We pass the ID (selectedOrganizationId) to the backend
        organization: selectedOrganizationId,
      });
      console.log("response", response.data);
      if (response.success) {
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        });
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }

      setNewUserData({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role: "",
      });
      setIsOpen(false);
      setIsGeneratingContent(false);
      // Refresh
      fetchUsers(selectedOrganizationId, currentPage, fileCount);
      fetchOrganizations();
    } catch (error) {
      setIsGeneratingContent(false);
      toast({
        title: "User Creation Failed",
        description: error.response.data.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGeneratingContent(false);
    }
  };

  const handleSubmit = () => {
    if (newUserData.password.length < 8) {
      setPasswordError("Password should be at least 8 characters.");
      toast({
        title: "Error",
        description: "Password should be at least 8 characters.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const validationErrors = validate(newUserData);
    if (Object.keys(validationErrors).length === 0) {
      addUser();
    } else {
      setErrors(validationErrors);
    }
  };

  // -----------------------------
  // Change Role
  // -----------------------------
  const changeUserRole = (email, role) => {
    axiosInstance
      .put("/auth/admin/change_role", { email, role })
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "Role changed successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: "An Error Occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        fetchUsers(selectedOrganizationId, currentPage, fileCount);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // -----------------------------
  // Delete User
  // -----------------------------
  const deleteUser = () => {
    axiosInstance
      .delete(
        `/auth/admin/delete_user?email=${encodeURIComponent(emailToDelete)}`
      )
      .then((response) => {
        fetchUsers(selectedOrganizationId, currentPage, fileCount);
        fetchOrganizations();
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "User Deleted Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // -----------------------------
  // Misc
  // -----------------------------
  const goToHomePage = () => {
    history.push("/program");
  };

  return (
    <Box width={{ base: "100%", md: "100%" }} height="100vh" display="flex">
      {/* LEFT SIDEBAR */}
      <Box w="17%" bg="#0F123F" borderRightRadius="xl">
        {/* Sidebar Header */}
        <Box
          width={{ base: "100%", md: "100%" }}
          height={{ base: "10vh", md: "15vh" }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          pt={4}
          pl={2}
          pr={4}
        >
          <HStack>
            <IconButton
              icon={<FiArrowLeft />}
              bg="transparent"
              color="white"
              onClick={() => history.goBack()}
              aria-label="Back"
              _hover="none"
            />
            <Text fontSize="md" fontWeight="semibold" mb="0" color="#EAEAEF">
              Organizations
            </Text>
          </HStack>
        </Box>

        {/* Accordion of Organizations */}
        <Box mt={2} mb={2} p={4} pt={0} h="80vh" justifyContent="space-between">
          <VStack
            spacing={2}
            w="100%"
            h="76vh"
            overflowY="scroll"
            sx={{
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Accordion allowToggle width="100%">
              {organizations.map((orgObj) => (
                <AccordionItem key={orgObj.organization}>
                  <AccordionButton
                    onClick={() =>
                      handleSetOrganization(
                        orgObj.organization,
                        orgObj.company_name
                      )
                    }
                    _hover={{ bg: "transparent" }}
                    _focus={{ boxShadow: "none" }}
                  >
                    <Box
                      flex="1"
                      textAlign="left"
                      color={
                        orgObj.organization === selectedOrganizationId
                          ? "#FEA130"
                          : "gray.200"
                      }
                      fontWeight={
                        orgObj.organization === selectedOrganizationId
                          ? "bold"
                          : "normal"
                      }
                    >
                      {/* Display only company_name here */}
                      {orgObj.company_name}
                    </Box>
                  </AccordionButton>
                </AccordionItem>
              ))}
            </Accordion>
          </VStack>

          {/* Home Page Button */}
          <Button
            bg="#FEA130"
            color="#FFFFFF"
            _hover="none"
            borderRadius="full"
            size="sm"
            onClick={goToHomePage}
          >
            Home Page
          </Button>
        </Box>
      </Box>

      {/* MAIN CONTENT */}
      <Box
        direction="column"
        height="100vh"
        width={{ base: "100%", md: "83%" }}
      >
        {/* Top Header */}
        <Box
          width={{ base: "100%", md: "100%" }}
          height="15vh"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          pt={10}
          pl={8}
          pr={5}
        >
          <Heading fontWeight="bold" mb="0" color="black">
            {/* Display only the organization name here, not the ID */}
            {selectedOrganizationName || "No Organization Selected"}
          </Heading>

          {/* <Spacer /> */}
          <HStack spacing={2}>
            <Button
              onClick={() => setShowAddUserModal(true)}
              bg="#231F1F"
              color="#FFFFFF"
              _hover={{ bg: "#404040" }}
              borderRadius="full"
              size="sm"
              mr={3}
              isDisabled={!selectedOrganizationId}
            >
              Add User
            </Button>

            <UserProfileMenu />
          </HStack>
        </Box>

        {/* Users Table */}
        <Box p={5} pl={8} pr={8} pb={2} h="80vh">
          <Box
            width={{ base: "100%", md: "100%" }}
            h="100%"
            bg="white"
            borderRadius="xl"
            p={3}
            pb={1}
            borderColor="blackAlpha.200"
            borderWidth="1px"
          >
            <Box h="100%" overflowY="auto">
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th>USERNAME</Th>
                    <Th>EMAIL</Th>
                    <Th>ROLE</Th>
                    {/* <Th pr={0}>
                      <Flex align="center">
                        ORGANIZATION
                        <Button
                          ml={2}
                          onClick={handleOpen}
                          size="sm"
                          variant="unstyled"
                        >
                          <Tooltip label="Settings">
                            <Icon as={SettingsIcon} />
                          </Tooltip>
                        </Button>
                      </Flex>
                    </Th> */}
                    <Th textAlign="center" pl={0}>
                      ACTIONS
                    </Th>
                    <Th>CHANGE ROLE</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Array.isArray(users) &&
                    users.slice(0, fileCount).map((user, index) => (
                      <Tr key={index}>
                        <Td>{user.username}</Td>
                        <Td>{user.email}</Td>
                        <Td>{user.role}</Td>

                        {/* This column might contain the raw ID from user.organization 
                            If you don't want to display it at all, remove or replace with user-friendly text. */}
                        {/* <Td pr={0}>
                          
                          {user.company_name}
                        </Td> */}

                        <Td textAlign="center" pl={0}>
                          <IconButton
                            icon={<DeleteIcon />}
                            colorScheme="red"
                            onClick={() => {
                              setEmailToDelete(user.email);
                              setDeleteUserAlertOpen(true);
                            }}
                            aria-label="Delete API Keys"
                            borderRadius="full"
                            size="sm"
                          />
                        </Td>
                        <Td>
                          <Select
                            value={user.role}
                            onChange={(e) => {
                              setUserToChange({
                                email: user.email,
                                role: e.target.value,
                              });
                              setIsRoleChangeAlertOpen(true);
                            }}
                            size="sm"
                            w="50%"
                            borderRadius="full"
                          >
                            <option value="" disabled>
                              Select a role
                            </option>
                            {roles.map((role, idx) => (
                              <option key={idx} value={role}>
                                {role}
                              </option>
                            ))}
                          </Select>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Box>
          </Box>

          {/* Pagination */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            fileCount={fileCount}
            setFileCount={setFileCount}
          />
        </Box>

        {/* Add User Modal */}
        <Modal
          isOpen={showAddUserModal}
          onClose={() => setShowAddUserModal(false)}
          size="lg"
        >
          <ModalOverlay />
          <ModalContent h="78vh">
            <ModalHeader>Add New User</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowY="auto">
              <FormControl isInvalid={errors.first_name}>
                <FormLabel fontWeight="semibold">First Name</FormLabel>
                <Input
                  placeholder="Your first name"
                  maxLength={25}
                  value={newUserData.first_name}
                  onChange={(e) =>
                    setNewUserData({
                      ...newUserData,
                      first_name: e.target.value,
                    })
                  }
                  borderRadius="full"
                />
                <FormErrorMessage>{errors.first_name}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.last_name} mt={4}>
                <FormLabel fontWeight="semibold">Last Name</FormLabel>
                <Input
                  placeholder="Your last name"
                  maxLength={25}
                  value={newUserData.last_name}
                  onChange={(e) =>
                    setNewUserData({
                      ...newUserData,
                      last_name: e.target.value,
                    })
                  }
                  borderRadius="full"
                />
                <FormErrorMessage>{errors.last_name}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.email} mt={4}>
                <FormLabel fontWeight="semibold">Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Your email"
                  maxLength={80}
                  value={newUserData.email}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, email: e.target.value })
                  }
                  borderRadius="full"
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!passwordError} mt={4}>
                <FormLabel fontWeight="semibold">Password</FormLabel>
                <Input
                  type="password"
                  placeholder="Your password"
                  value={newUserData.password}
                  onChange={(e) => {
                    const pwd = e.target.value;
                    if (pwd.length < 8) {
                      setPasswordError(
                        "Password should be at least 8 characters."
                      );
                      setIsPasswordValid(false);
                    } else {
                      setPasswordError("");
                      setIsPasswordValid(true);
                    }
                    setNewUserData({ ...newUserData, password: pwd });
                  }}
                  borderRadius="full"
                />
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.role} mt={4}>
                <FormLabel fontWeight="semibold">Role</FormLabel>
                <Select
                  value={newUserData.role}
                  onChange={(e) =>
                    setNewUserData({ ...newUserData, role: e.target.value })
                  }
                  borderRadius="full"
                >
                  <option value="" disabled>
                    Select a role
                  </option>
                  {roles.map((role, idx) => (
                    <option key={idx} value={role}>
                      {role}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.role}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                bg="#231F1F"
                color="#FFFFFF"
                _hover={{ bg: "#404040" }}
                borderRadius="full"
                size="sm"
                onClick={handleSubmit}
                isDisabled={!isPasswordValid}
                isLoading={isGeneratingContent}
              >
                Add
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* Delete User Alert */}
        <AlertDialog
          isOpen={isDeleteUserAlertOpen}
          onClose={() => setDeleteUserAlertOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete User
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to delete the user?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setDeleteUserAlertOpen(false)}
                >
                  No
                </Button>
                <Button
                  colorScheme="red"
                  bgColor="red.500"
                  textColor="white"
                  onClick={() => {
                    deleteUser();
                    setDeleteUserAlertOpen(false);
                  }}
                  ml={3}
                >
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* Change Role Alert */}
        <AlertDialog
          isOpen={isRoleChangeAlertOpen}
          onClose={() => setIsRoleChangeAlertOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Change Role
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to change the role?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  variant="outline"
                  onClick={() => setIsRoleChangeAlertOpen(false)}
                >
                  No
                </Button>
                <Button
                  colorScheme="red"
                  bgColor="red.500"
                  textColor="white"
                  onClick={() => {
                    changeUserRole(userToChange.email, userToChange.role);
                    setIsRoleChangeAlertOpen(false);
                  }}
                  ml={3}
                >
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        {/* Organization Settings Modal */}
        <OrganizationSettings
          isOpen={isOpen}
          onClose={handleClose}
          selectedOrganization={selectedOrganizationId} // ID
          setSelectedOrganization={setSelectedOrganizationId}
          fetchOrganizations={fetchOrganizations}
          fetchUsers={fetchUsers}
        />
      </Box>
    </Box>
  );
}

export default AdminPanel;
