import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Spacer,
  useColorMode,
  Text,
} from "@chakra-ui/react";
import ChartComponent from "./ChartComponent"; // Import the ChartComponent
import Pagination from "./Pagination"; // Import the Pagination component
import HistoryTable from "./HistoryTable"; // Import the HistoryTable component
import UserProfileMenu from "../../UserProfileMenu";
import CreateButton from "../CreateButton";

const MiddlePanel = ({
  selectedProgram,
  fetchSelectedProgram,
  currentPage,
  setCurrentPage,
  fileCount,
  setFileCount,
  programs,
  setPrograms,
  showInputField,
  setShowInputField,
  currentProgram,
  setCurrentProgram,
  handleAddProgram,
  totalPages,
  contentType,
  setContentType,
}) => {
  const { colorMode } = useColorMode();
  const [data, setData] = useState([]);
  // const [viewDetails, setViewDetails] = useState(null); // State to manage view details
  const [apiKeysAvailable, setApiKeysAvailable] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const username = localStorage.getItem("username");

  useEffect(() => {
    if (selectedProgram) {
      fetchSelectedProgram(selectedProgram._id, currentPage, fileCount);
    }
  }, [fileCount]);

  useEffect(() => {
    if (selectedProgram) {
      const fileList = selectedProgram.file_list || [];
      setData(fileList);
    }
  }, [selectedProgram]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      // Use the function form of setCurrentPage to ensure correct update
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchSelectedProgram(selectedProgram._id, nextPage, fileCount); // Pass nextPage directly
        // console.log("current page (after increment):", nextPage);
        return nextPage;
      });
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const prevPageNumber = prevPage - 1;
        fetchSelectedProgram(selectedProgram._id, prevPageNumber, fileCount);
        return prevPageNumber;
      });
    }
  };

  return (
    <Flex direction="column" height="100vh" width={{ base: "83%", md: "83%" }}>
      {/* --- Header (fixed 15vh) --- */}
      <Box
        width="100%"
        height="15vh"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
        pt={10}
        pl={8}
        pr={5}
      >
        <UserProfileMenu />
      </Box>

      {/* --- Main Content (flex=1) --- */}

      {/* If a campaign is selected & programs exist -> Show Chart */}
      {programs.length !== 0 && selectedProgram && (
        <ChartComponent selectedProgram={selectedProgram} />
      )}

      {/* If NO programs exist */}
      {programs.length === 0 && (
        <Flex
          flex="1"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Heading fontSize="lg" fontWeight="bold" mt={4} mb={2}>
            It looks like you haven't created any campaigns yet.
          </Heading>
          <Text fontSize="sm" color="gray.500" mb={2}>
            Create Your First Campaign.
          </Text>
          <CreateButton
            showInputField={showInputField}
            setShowInputField={setShowInputField}
            currentProgram={currentProgram}
            setCurrentProgram={setCurrentProgram}
            handleAddProgram={handleAddProgram}
            width="100%"
            color="black"
          />
        </Flex>
      )}

      {/* If programs exist but no program is selected */}
      {!selectedProgram && programs.length !== 0 && (
        <Flex
          flex="1"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Heading fontSize="lg" fontWeight="bold" mt={4} mb={2}>
            No Campaign Cluster Selected. Please select a Cluster from left panel.
          </Heading>
        </Flex>
      )}

      {/* If a campaign is selected & programs exist -> Show Table + Pagination */}
      {programs.length !== 0 && selectedProgram && (
      <Box
        mt={0}
        mb={2}
        p={5}
        pl={8}
        pr={8}
        pt={2}
        pb={0}
        overflowY="scroll"
        flex="1" // Make this section take up remaining space
        sx={{
          "&::-webkit-scrollbar": { display: "none" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <HistoryTable
          selectedProgram={selectedProgram}
          data={data}
          setData={setData}
          apiKeysAvailable={apiKeysAvailable}
          setApiKeysAvailable={setApiKeysAvailable}
          fetchSelectedProgram={fetchSelectedProgram}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          contentType={contentType}
          setContentType={setContentType}
        />
      </Box>
      )}
      {programs.length !== 0 && selectedProgram && (
        <Box height="auto">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            fileCount={fileCount}
            setFileCount={setFileCount}
          />
        </Box>
      )}
    </Flex>
  );
};

export default MiddlePanel;
