import React, { useEffect, useState } from "react";
import {
   Text,
  } from "@chakra-ui/react";
import axiosInstance from "../helpers/axiosInstance"; // Import your Axios instance or Axios directly

const CurrentUserOrganization = () => {
  const [organization, setOrganization] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Define an async function to fetch the current user's organization
    const fetchCurrentUserOrganization = async () => {
      try {
        // Make a GET request to the backend endpoint
        const response = await axiosInstance.get(
          "/org/get_current_organization"
        );
        // console.log("fetchCurrentUserOrganization",response.data.data.company_name)
        // Check if the response contains the organization data
        if (response.data && response.data.data && response.data.data.company_name) {
          setOrganization(response.data.data.company_name);
        } else {
          // Handle the case where the organization is not found or not set
          setOrganization("Organization not set");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching current user's organization:", error);
        setLoading(false);
      }
    };

    // Call the fetchCurrentUserOrganization function
    fetchCurrentUserOrganization();
  }, []);

  return (
    <div>
      {loading ? (
        <p></p>
      ) : (
        <Text mt={4} mr={2} fontWeight="semibold">
         {organization}
        </Text>
      )}
    </div>
  );
};

export default CurrentUserOrganization;
