import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Button,
  Image,
  useColorMode,
  useToast,
  Skeleton,
  SkeletonText,
  Tooltip,
} from "@chakra-ui/react";
import {
  FiArrowLeft,
  FiMail,
  FiPhone,
  FiCopy,
  FiDownload,
} from "react-icons/fi";
import { useParams, useHistory ,Link} from "react-router-dom"; // Import useParams and useHistory
import { AiOutlineHome } from "react-icons/ai";
import InfoModal from "../InfoModal";
import axiosInstance from "../../../../helpers/axiosInstance";
import linkedinIcon from "../../../../assets/icons/linkedin.png"; // Import the LinkedIn icon image
import ViewDetailsPagination from "./ViewDetailsPagination";

const ViewExecuteContent = () => {
  const { programId, fileUniqueId, executionId } = useParams(); // Destructure params from URL
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Start with a default value
  const [fileCount, setFileCount] = useState(15);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState("");
  const { colorMode } = useColorMode();
  const toast = useToast();
  const history = useHistory();
  const [contentType, setContentType] = useState("personalised"); // Default value
  const columnCount = contentType !== "company" ? 7 : 6;

  useEffect(() => {
    const storedContentType = localStorage.getItem("contentType");
    const sanitizedContentType = storedContentType
      ? storedContentType.replace(/^"|"$/g, "") // Remove surrounding quotes if present
      : "personalised"; // Default to "personalised" if not found

    // console.log("sanitizedContentType", sanitizedContentType);
    setContentType(sanitizedContentType);
  }, []);

  useEffect(() => {
    // Fetch the execution data when the component mounts
    fetchExecutionData(currentPage, fileCount);
  }, [programId, fileUniqueId, executionId, currentPage, fileCount]); // Add fetchExecutionData to dependencies

  const fetchExecutionData = async (page, count) => {
    try {
      const response = await axiosInstance.post(
        `/content-generation/get-execution-data-by-execution-id/${programId}/${fileUniqueId}/execution`,
        {
          file_count: count,
          page_no: page,
        }
      );

      if (response.status === 200) {
        const data = response.data.data;
        setFileData(data.file_data);
        console.log(data.file_data);
        setTotalPages(data.page_count || 1); // Set total pages
      }
    } catch (error) {
      console.error("Error fetching execution data:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching the execution data.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchExecutionData(nextPage, fileCount);
        return nextPage;
      });
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const prevPageNumber = prevPage - 1;
        fetchExecutionData(prevPageNumber, fileCount);
        return prevPageNumber;
      });
    }
  };

  const handleIconClick = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    onOpen();
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleDownload = async () => {
    try {
      const response = await axiosInstance.get(
        `/content-generation/download-file-by-execution/${programId}/${fileUniqueId}/${executionId}`,
        {
          responseType: "blob", // Important to handle binary data
        }
      );

      // Get the filename from the response headers
      const contentDisposition = response.headers["content-disposition"];
      let filename = contentDisposition
        ? contentDisposition.split("filename=")[1].split(";")[0].trim()
        : "downloaded_file.xlsx";

      // Clean up the filename if it has extra underscores or other unwanted characters
      filename = filename.replace(/^_+|_+$/g, "").replace(/"/g, "");

      // Create a link element and trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Use the cleaned-up filename
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error fetching file data:", error);
      let errorMessage = "An error occurred while downloading the file.";

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      console.error("Error downloading file:", error);
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Flex
      direction="column"
      height="100vh"
      width={{ base: "100%", md: "100%" }}
    >
      {/* Top Row */}
      <Box
        width={{ base: "100%", md: "100%" }}
        height="7vh"
        bg={colorMode === "dark" ? "gray.800" : "white"}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={1}
        pl={2}
        pr={4}
        pt={2}
        pb={2}
        borderBottom="1px"
        borderColor="blackAlpha.200"
      >
        <Box display="flex" alignItems="center" justifyContent="center" ml={4}>
          {/* <IconButton
            icon={<FiArrowLeft />}
            bg="transparent"
            onClick={() => history.goBack()} // Go back to the previous page
            aria-label="Back"
            _hover="none"
          />
          <Text ml={0} fontSize="md" mb={0}>
            {selectedProgram?.name || "Back"}
          </Text> */}
         
              <Link to="/program">
                <AiOutlineHome cursor="pointer" size={24}/>
              </Link>
            
        </Box>
        <Button
          size="sm"
          bg="black"
          color="white"
          _hover="none"
          onClick={handleDownload}
          mr={2}
        >
          Download File
        </Button>
      </Box>
      <Box p={8} pb={2} w="100%" align="center" justify="center">
        <Box
          width={{ base: "100%", md: "80%" }}
          bg="white"
          p={4}
          overflowY="auto"
          align="center"
          borderRadius="xl"
          borderColor="blackAlpha.200"
          borderWidth="1px"
          h="75vh"
        >
          <Table variant="simple" size="sm" w="100%">
            <Thead>
              <Tr>
                {contentType !== "company" && <Th textAlign="left">Name</Th>}{" "}
                {/* Conditionally render Name column */}
                <Th textAlign="left">Company</Th>
                <Th textAlign="center">Initial Email</Th>
                <Th textAlign="center">LinkedIn Connect Note</Th>
                <Th textAlign="center">Follow Up Email</Th>
                <Th textAlign="center">LinkedIn Message</Th>
                <Th textAlign="center">⁠Cold Calling Script</Th>
              </Tr>
            </Thead>
            <Tbody>
              {fileData.length === 0 ? (
                <Tr>
                  <Td colSpan={columnCount} textAlign="center">
                    {/* Three skeleton blocks of 40px each */}
                    <Skeleton height="40px" />
                    <Skeleton height="40px" mt="1" />
                    <Skeleton height="40px" mt="1" />
                  </Td>
                </Tr>
              ) : (
                fileData.map((detail, index) => (
                  <Tr key={index}>
                    {contentType !== "company" && (
                      <Td textAlign="left">{detail.prospect_name}</Td>
                    )}
                    <Td textAlign="left">{detail.prospect_company_name}</Td>
                    <Td textAlign="center">
                      <IconButton
                        icon={<FiMail />}
                        size="sm"
                        bg="transparent"
                        aria-label="Mail"
                        onClick={() =>
                          handleIconClick(
                            detail["1st_email_subject"],
                            detail["1st_email_body"]
                          )
                        }
                      />
                    </Td>
                    <Td textAlign="center">
                      <IconButton
                        icon={
                          <Image
                            src={linkedinIcon}
                            boxSize="4"
                            alt="LinkedIn"
                          />
                        }
                        aria-label="LinkedIn"
                        size="sm"
                        bg="transparent"
                        onClick={() =>
                          handleIconClick(
                            "LinkedIn Connect Note",
                            detail.linkedin_connect_note
                          )
                        }
                      />
                    </Td>
                    <Td textAlign="center">
                      <IconButton
                        icon={<FiMail />}
                        size="sm"
                        bg="transparent"
                        aria-label="Mail"
                        onClick={() =>
                          handleIconClick(
                            detail["2nd_email_subject"],
                            detail["2nd_email_body"]
                          )
                        }
                      />
                    </Td>
                    <Td textAlign="center">
                      <IconButton
                        icon={
                          <Image
                            src={linkedinIcon}
                            boxSize="4"
                            alt="LinkedIn"
                          />
                        }
                        aria-label="LinkedIn"
                        size="sm"
                        bg="transparent"
                        onClick={() =>
                          handleIconClick(
                            "LinkedIn Message",
                            detail.linkedin_message
                          )
                        }
                      />
                    </Td>
                    <Td textAlign="center">
                      <IconButton
                        icon={<FiPhone />}
                        size="sm"
                        bg="transparent"
                        aria-label="Cold Calling Script"
                        onClick={() =>
                          handleIconClick(
                            "Cold Calling Script",
                            detail.cold_calling_reachout_script
                          )
                        }
                      />
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </Box>
        <Box
          width={{ base: "100%", md: "80%" }}
          bg="white"
          p={4}
          pr={0}
          overflowY="auto"
          align="center"
        >
          <ViewDetailsPagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            fileCount={fileCount}
            setFileCount={setFileCount}
          />
        </Box>
      </Box>
      <InfoModal
        isOpen={isOpen}
        onClose={onClose}
        title={modalTitle}
        content={modalContent}
      >
        <Button onClick={() => handleCopy(modalContent)} leftIcon={<FiCopy />}>
          Copy
        </Button>
      </InfoModal>
    </Flex>
  );
};

export default ViewExecuteContent;
