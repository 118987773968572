import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
  Image,
  HStack,
  Link,
  Divider,
  VStack
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
// import logo from "../assets/images/logo_auth.png"; // Adjust path if needed
import logo from "../assets/images/logo-home-dark.png";
import "./../styles/styles.css";

const content = [
  {
    heading: "Reset Your Password",
    text: "enter your new password.",
  },
];

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();
  const toast = useToast();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast({
        title: "Password Mismatch",
        description: "The passwords do not match. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return; // Stop the form submission if passwords do not match
    }

    try {
      await axios.post(`/auth/reset_password/${token}`, { password });
      toast({
        title: "Password Reset Successful",
        description: "Your password has been successfully reset.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      history.push("/login");
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : "An error occurred while trying to reset your password. Please try again.";
      toast({
        title: "An error occurred.",
        description: errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationTrigger, setAnimationTrigger] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setAnimationTrigger((prev) => !prev); // Toggle the trigger
        return (prevIndex + 1) % content.length;
      });
    }, 9000); // Change content every 4 seconds
    return () => clearInterval(timer);
  }, []);

  return (
    <Flex h="100vh">
      {/* Left Side with Lavender Background */}
      {/* <Box w="60%" bg="#FAC900" pt={5} pl={5} pb={40}>
      <Link as={RouterLink} to="/home">
        <Image src={logo} alt="Logo" width="100px" height="auto" />
        </Link>
        <Container w="100%" ml={0} pt={36}>
          <Heading
            // color="#231F1F"
            color="white"
            textAlign="left"
            fontFamily="'ui', sans-serif"
          >
            {content[currentIndex].heading}
          </Heading>
          <Text
            // color="#231F1F"
            color="white"
            className={animationTrigger ? "typewriter" : "typewriter alternate"}
            key={currentIndex}
            textAlign="left"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
          >
            {content[currentIndex].text}
          </Text>
        </Container>
      </Box> */}
      <Box
        w="60%"
        minH="100vh" // Ensures the Box has enough height to center vertically
        bg="#FAC900"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <VStack>
          <Link as={RouterLink} to="/home">
            <Image src={logo} alt="Logo" width="300px" height="auto" />
          </Link>
          <Text
            color="#0F123F"
            className={`typewriter ${animationTrigger ? "alternate" : ""}`}
            key={currentIndex}
            textAlign="left"
            fontFamily="'ui', sans-serif"
            fontWeight="bold"
            fontSize="lg"
          >
            {content[currentIndex].text}
          </Text>
        </VStack>
      </Box>
      {/* Right Side with Reset Password Form */}
      <Box w="40%" bg="white" p={10} pt="40" pb="40">
        <Container>
          <Stack spacing={4}>
            <Heading
              as="h1"
              size="xl"
              textAlign="left"
              mb={6}
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#3A4135"
            >
              Reset Password
            </Heading>
            <form onSubmit={handleSubmit}>
              <FormControl>
                <FormLabel
                  htmlFor="password"
                  fontFamily="'Manrope', sans-serif"
                  fontWeight="medium"
                  color="#3A4135"
                >
                  New Password*
                </FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl mt="4">
                <FormLabel
                  htmlFor="confirmPassword"
                  fontFamily="'Manrope', sans-serif"
                  fontWeight="medium"
                  color="#3A4135"
                >
                  Confirm New Password*
                </FormLabel>
                <Input
                  id="confirmPassword"
                  type="password"
                  placeholder="Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </FormControl>
              <Button
                mt={4}
                type="submit"
                w="100%"
                bg="#231F1F"
                color="#FFFFFF"
                fontFamily="'Roboto', sans-serif"
                fontWeight="medium"
                _hover={{ bg: "#404040" }}
              >
                Update Password
              </Button>
            </form>
          </Stack>
        </Container>
        <Box alignContent="center" justifyContent="center" p={5} pb={0} pt={20}>
          <HStack alignContent="center" justifyContent="center">
            <Link
              fontSize="12"
              href="/terms-of-service"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
              // mr={4}
            >
              Terms of Use
            </Link>
            <Divider
              alignSelf="center"
              orientation="vertical"
              height="12px" // Adjust height according to your needs
              borderColor="black" // Ensure this is visible against your background
              borderWidth="0.5px"
            />
            <Link
              fontSize="12"
              href="/privacy-policy"
              fontFamily="'Manrope', sans-serif"
              fontWeight="medium"
              color="#955514"
            >
              Privacy Policy
            </Link>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
}

export default ResetPassword;
